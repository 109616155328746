/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
.GoalDossier__container--3V12M {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 60px;
  padding-left: 60px;
  height: 100%;
  overflow-y: scroll;
  -webkit-box-shadow: -3px 0px 7px 1px rgba(0, 0, 0, 0.25);
          box-shadow: -3px 0px 7px 1px rgba(0, 0, 0, 0.25); }
  .GoalDossier__container--3V12M[dir="rtl"],
  [dir="rtl"] .GoalDossier__container--3V12M {
    padding-right: 60px;
    padding-left: 60px; }

.GoalDossier__closeButton--2RBsA {
  position: absolute;
  top: 0;
  left: 0; }

.GoalDossier__tabs--30CIc {
  margin-bottom: 20px; }
