.CopyToClipboardButton {
  margin: 0;
  padding: 0;
}
.CopyToClipboardButton--tooltip {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
  padding: 10px 20px;
}
