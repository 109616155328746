/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.TeamFeedbackRequest__page--1b5AD {
  margin-right: 100px;
  margin-left: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .TeamFeedbackRequest__page--1b5AD[dir="rtl"],
  [dir="rtl"] .TeamFeedbackRequest__page--1b5AD {
    margin-right: 1.5rem;
    margin-left: 100px; }

.TeamFeedbackRequest__content--2LgK_ {
  max-width: 850px;
  width: 100%;
  padding: 45px 20px 40px;
  margin: 0 auto;
  text-align: left; }

.TeamFeedbackRequest__reviewersSelector--3frz1 {
  margin-bottom: 1.5rem;
  margin-right: 0;
  margin-left: 0; }
  .TeamFeedbackRequest__reviewersSelector--3frz1[dir="rtl"],
  [dir="rtl"] .TeamFeedbackRequest__reviewersSelector--3frz1 {
    margin-right: 0;
    margin-left: 0; }

.TeamFeedbackRequest__questions--o-jOw {
  width: 100%;
  margin-bottom: 1.5rem;
  margin-right: 0;
  margin-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .TeamFeedbackRequest__questions--o-jOw[dir="rtl"],
  [dir="rtl"] .TeamFeedbackRequest__questions--o-jOw {
    margin-right: 0;
    margin-left: 0; }
  .TeamFeedbackRequest__questions--o-jOw > *:first-child {
    margin-bottom: 1.5rem;
    margin-right: 0;
    margin-left: 0; }
    .TeamFeedbackRequest__questions--o-jOw > *:first-child[dir="rtl"],
    [dir="rtl"] .TeamFeedbackRequest__questions--o-jOw > *:first-child {
      margin-right: 0;
      margin-left: 0; }

.TeamFeedbackRequest__surveyQuestions--niUAz {
  -ms-flex-item-align: stretch;
      align-self: stretch; }

.TeamFeedbackRequest__actionButtonsContaienr--3ggT_ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  width: 100%; }

.TeamFeedbackRequest__cancelButtonContainer--sHfTz {
  margin-right: 0.75rem;
  margin-left: 0; }
  .TeamFeedbackRequest__cancelButtonContainer--sHfTz[dir="rtl"],
  [dir="rtl"] .TeamFeedbackRequest__cancelButtonContainer--sHfTz {
    margin-right: 0;
    margin-left: 0.75rem; }

.TeamFeedbackRequest__titleContainer--ZbYDu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 1.5rem;
  margin-right: 0;
  margin-left: 0; }
  .TeamFeedbackRequest__titleContainer--ZbYDu[dir="rtl"],
  [dir="rtl"] .TeamFeedbackRequest__titleContainer--ZbYDu {
    margin-right: 0;
    margin-left: 0; }

.TeamFeedbackRequest__typeaheadContainer--3F2OK {
  margin-bottom: 1.5rem;
  margin-right: 0;
  margin-left: 0; }
  .TeamFeedbackRequest__typeaheadContainer--3F2OK[dir="rtl"],
  [dir="rtl"] .TeamFeedbackRequest__typeaheadContainer--3F2OK {
    margin-right: 0;
    margin-left: 0; }

.TeamFeedbackRequest__avatarContainer--2EfFs {
  margin-right: 0.375rem;
  margin-left: 0; }
  .TeamFeedbackRequest__avatarContainer--2EfFs[dir="rtl"],
  [dir="rtl"] .TeamFeedbackRequest__avatarContainer--2EfFs {
    margin-right: 0;
    margin-left: 0.375rem; }
