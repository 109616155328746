/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
.Avatar__container--28MBy {
  overflow: hidden;
  padding: 1px;
  border: 1px solid #e1e2ea;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fff; }
  .Avatar__container--28MBy img {
    width: 100%; }

.Avatar__small--25Dca {
  width: 40px;
  height: 40px;
  border-radius: 20px; }
  .Avatar__small--25Dca img {
    border-radius: 20px; }

.Avatar__medium--2iwjn {
  width: 50px;
  height: 50px;
  border-radius: 25px; }
  .Avatar__medium--2iwjn img {
    border-radius: 25px; }

.Avatar__large--jbvvS {
  width: 60px;
  height: 60px;
  border-radius: 30px; }
  .Avatar__large--jbvvS img {
    border-radius: 30px; }

.Avatar__xlarge--zvyIg {
  width: 124px;
  height: 124px;
  border-radius: 62px; }
  .Avatar__xlarge--zvyIg img {
    border-radius: 62px; }
