/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.CompanyGoalForm__container--2A4lQ {
  max-width: 1080px;
  margin: auto; }
  @media (max-width: 767px) {
    .CompanyGoalForm__container--2A4lQ {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-right: 0.75rem;
      padding-left: 0.75rem; }
      .CompanyGoalForm__container--2A4lQ[dir="rtl"],
      [dir="rtl"] .CompanyGoalForm__container--2A4lQ {
        padding-right: 0.75rem;
        padding-left: 0.75rem; } }

.CompanyGoalForm__section--15PIJ {
  margin-bottom: 1.5rem;
  margin-right: 0;
  margin-left: 0; }
  .CompanyGoalForm__section--15PIJ[dir="rtl"],
  [dir="rtl"] .CompanyGoalForm__section--15PIJ {
    margin-right: 0;
    margin-left: 0; }

.CompanyGoalForm__row--MOPtU {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }
  @media (max-width: 1023px) {
    .CompanyGoalForm__row--MOPtU {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      margin-bottom: 1.5rem;
      margin-right: 0;
      margin-left: 0; }
      .CompanyGoalForm__row--MOPtU[dir="rtl"],
      [dir="rtl"] .CompanyGoalForm__row--MOPtU {
        margin-right: 0;
        margin-left: 0; } }

.CompanyGoalForm__field--3Dmvy {
  margin-right: 0.75rem;
  margin-left: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0; }
  .CompanyGoalForm__field--3Dmvy[dir="rtl"],
  [dir="rtl"] .CompanyGoalForm__field--3Dmvy {
    margin-right: 0;
    margin-left: 0.75rem; }
  .CompanyGoalForm__field--3Dmvy:last-child {
    margin-right: 0;
    margin-left: 0; }
    .CompanyGoalForm__field--3Dmvy:last-child[dir="rtl"],
    [dir="rtl"] .CompanyGoalForm__field--3Dmvy:last-child {
      margin-right: 0;
      margin-left: 0; }
  @media (max-width: 1023px) {
    .CompanyGoalForm__field--3Dmvy {
      width: 100%; } }

.CompanyGoalForm__large---RD36 {
  -webkit-box-flex: 5;
      -ms-flex-positive: 5;
          flex-grow: 5; }

.CompanyGoalForm__small--37YBk {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

.CompanyGoalForm__actions--3lFcF {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

.CompanyGoalForm__action--1dvbZ {
  margin-right: 0.75rem;
  margin-left: 0; }
  .CompanyGoalForm__action--1dvbZ[dir="rtl"],
  [dir="rtl"] .CompanyGoalForm__action--1dvbZ {
    margin-right: 0;
    margin-left: 0.75rem; }
  .CompanyGoalForm__action--1dvbZ:last-child {
    margin-right: 0;
    margin-left: 0; }
    .CompanyGoalForm__action--1dvbZ:last-child[dir="rtl"],
    [dir="rtl"] .CompanyGoalForm__action--1dvbZ:last-child {
      margin-right: 0;
      margin-left: 0; }

.CompanyGoalForm__selectField--3ewCw {
  display: block; }

.CompanyGoalForm__goalOwnerSelect--1X92z {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.CompanyGoalForm__labelContainer--L6Dgo {
  margin-right: 0;
  margin-left: 0.75rem; }
  .CompanyGoalForm__labelContainer--L6Dgo[dir="rtl"],
  [dir="rtl"] .CompanyGoalForm__labelContainer--L6Dgo {
    margin-right: 0.75rem;
    margin-left: 0; }

.CompanyGoalForm__detailedDescriptionContainer--2QmWE {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%; }

.CompanyGoalForm__detailedDescriptionButton--Nje2u {
  margin-left: auto; }

.CompanyGoalForm__labelText--3Klvp {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1.125rem;
  line-height: 1.33333;
  position: relative;
  top: 0.30667em;
  margin-bottom: 0.75rem;
  margin-right: 0;
  margin-left: 0; }
  html:lang(he) .CompanyGoalForm__labelText--3Klvp {
    font-family: "Open Sans", Tahoma, sans-serif; }
  html:lang(ar) .CompanyGoalForm__labelText--3Klvp {
    font-family: "Open Sans", Tahoma, sans-serif; }
  html:lang(he) .CompanyGoalForm__labelText--3Klvp {
    font-family: "Ideal Sans A", "Ideal Sans B", Tahoma, sans-serif; }
  html:lang(ar) .CompanyGoalForm__labelText--3Klvp {
    font-family: "Ideal Sans A", "Ideal Sans B", Tahoma, sans-serif; }
  .CompanyGoalForm__labelText--3Klvp[dir="rtl"],
  [dir="rtl"] .CompanyGoalForm__labelText--3Klvp {
    margin-right: 0;
    margin-left: 0; }

.CompanyGoalForm__titleContainer--3b9o9 {
  margin-bottom: 0.375rem;
  margin-right: 0;
  margin-left: 0; }
  .CompanyGoalForm__titleContainer--3b9o9[dir="rtl"],
  [dir="rtl"] .CompanyGoalForm__titleContainer--3b9o9 {
    margin-right: 0;
    margin-left: 0; }

.CompanyGoalForm__priorityContainer--1HY76 {
  padding-top: 0.75rem;
  padding-right: 0;
  padding-left: 0; }
  .CompanyGoalForm__priorityContainer--1HY76[dir="rtl"],
  [dir="rtl"] .CompanyGoalForm__priorityContainer--1HY76 {
    padding-right: 0;
    padding-left: 0; }
