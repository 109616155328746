.BucketSummary {
  margin-bottom: 15px;
}
@media (max-width: 500px) {
  .BucketSummary,
  .BucketSummary.layout {
    display: none;
  }
}
.BucketSummary--tooltip {
  padding: 16px;
  max-width: 300px;
}
.BucketSummary--bucket {
  position: relative;
  border: 0 solid #e1e2ea;
  padding: 13px;
  border-width: 1px 0 1px 1px;
  min-width: 0;
  background-color: white;
}
.BucketSummary--bucket > * {
  line-height: normal;
}
.BucketSummary--bucket h5 {
  color: #35374a;
  font-size: 18px;
  line-height: 1.8rem;
  font-weight: 600;
  margin: 0;
  max-width: 100%;
  overflow-wrap: break-word;
}
.BucketSummary--bucket:last-child {
  border-right-width: 1px;
}
.BucketSummary--bucket,
.BucketSummary--bucket.flex,
.BucketSummary--bucket.flex-1 {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
}
.BucketSummary--bucket,
.BucketSummary--bucket h5 {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.BucketSummary--title {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1.125rem;
  line-height: 1.33333333;
  position: relative;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  max-width: 100%;
}
.BucketSummary--value {
  font-family: "Greycliff CF", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: -0.03em;
  font-size: 2.25rem;
  line-height: 1;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.BucketSummary--count {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
  opacity: 0.7;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.BucketSummary--value,
.BucketSummary--count,
.BucketSummary--value.flex,
.BucketSummary--count.flex {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.BucketSummary--section-title {
  border: 0 solid #e1e2ea;
  border-width: 1px 1px 0 1px;
  padding: 0px;
  margin: 0px;
}
.BucketSummary--section-title-text {
  margin-top: 10px;
  margin-bottom: 10px;
}
.BucketSummary--arrow-left,
.BucketSummary--arrow-right {
  border: 0 solid #e1e2ea;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  color: rgba(53, 55, 74, 0.8);
}
.BucketSummary--arrow-left {
  border-width: 1px 0 1px 1px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.BucketSummary--arrow-right {
  border-width: 1px 1px 1px 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.BucketSummary--section {
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  padding: 5px;
  min-width: 100%;
}
.BucketSummary--sections {
  overflow: hidden;
  min-width: 0;
  margin: -5px;
}
.BucketSummary--section-data {
  min-width: 100%;
  width: 100%;
}
.BucketSummary--bucket-selected {
  background-color: #f6f6f6;
}
.BucketSummary--bucket-single:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.BucketSummary--bucket-single:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.BucketSummary--bucket-clickable {
  cursor: pointer;
}
.BucketSummary--bucket-clickable:hover {
  background-color: #f6f6f6;
}
.BucketSummary--buttons-steps-btn-wrapper {
  padding-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: stretch;
      align-content: stretch;
}
.BucketSummary--buttons-steps-btn-wrapper div:first-of-type button {
  width: 180px;
  height: 40px;
  border-radius: 3px 0 0 3px;
}
.BucketSummary--buttons-steps-btn-wrapper div:first-of-type button span {
  padding: 0 !important;
}
.BucketSummary--buttons-steps-btn-wrapper div:last-of-type button {
  width: 180px;
  height: 40px;
  border-radius: 0 3px 3px 0;
}
.BucketSummary--buttons-steps-btn-wrapper div:last-of-type button span {
  padding: 0 3px !important;
}
.BucketSummary--buttons-steps-btn.active button {
  background-color: #f3f3f4;
}
