/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.TeamGoalForm__container--2dg7G {
  max-width: 1080px;
  margin: auto; }
  @media (max-width: 767px) {
    .TeamGoalForm__container--2dg7G {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-right: 0.75rem;
      padding-left: 0.75rem; }
      .TeamGoalForm__container--2dg7G[dir="rtl"],
      [dir="rtl"] .TeamGoalForm__container--2dg7G {
        padding-right: 0.75rem;
        padding-left: 0.75rem; } }

.TeamGoalForm__section--1BGWW {
  margin-bottom: 1.5rem;
  margin-right: 0;
  margin-left: 0;
  position: relative; }
  .TeamGoalForm__section--1BGWW[dir="rtl"],
  [dir="rtl"] .TeamGoalForm__section--1BGWW {
    margin-right: 0;
    margin-left: 0; }

.TeamGoalForm__row--W-hlv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }
  @media (max-width: 1023px) {
    .TeamGoalForm__row--W-hlv {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      margin-bottom: 1.5rem;
      margin-right: 0;
      margin-left: 0; }
      .TeamGoalForm__row--W-hlv[dir="rtl"],
      [dir="rtl"] .TeamGoalForm__row--W-hlv {
        margin-right: 0;
        margin-left: 0; } }

.TeamGoalForm__field--1D3-h {
  margin-right: 0.75rem;
  margin-left: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0; }
  .TeamGoalForm__field--1D3-h[dir="rtl"],
  [dir="rtl"] .TeamGoalForm__field--1D3-h {
    margin-right: 0;
    margin-left: 0.75rem; }
  .TeamGoalForm__field--1D3-h:last-child {
    margin-right: 0;
    margin-left: 0; }
    .TeamGoalForm__field--1D3-h:last-child[dir="rtl"],
    [dir="rtl"] .TeamGoalForm__field--1D3-h:last-child {
      margin-right: 0;
      margin-left: 0; }
  @media (max-width: 1023px) {
    .TeamGoalForm__field--1D3-h {
      width: 100%; } }

.TeamGoalForm__large--2fsj- {
  -webkit-box-flex: 5;
      -ms-flex-positive: 5;
          flex-grow: 5; }

.TeamGoalForm__small--1yhWB {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

.TeamGoalForm__actions--6jsl7 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

.TeamGoalForm__action--Zftjd {
  margin-right: 0.75rem;
  margin-left: 0; }
  .TeamGoalForm__action--Zftjd[dir="rtl"],
  [dir="rtl"] .TeamGoalForm__action--Zftjd {
    margin-right: 0;
    margin-left: 0.75rem; }
  .TeamGoalForm__action--Zftjd:last-child {
    margin-right: 0;
    margin-left: 0; }
    .TeamGoalForm__action--Zftjd:last-child[dir="rtl"],
    [dir="rtl"] .TeamGoalForm__action--Zftjd:last-child {
      margin-right: 0;
      margin-left: 0; }

.TeamGoalForm__selectField--20Pgc {
  display: block; }

.TeamGoalForm__goalOwnerSelect--1qd6D {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.TeamGoalForm__labelContainer--18rLE {
  margin-right: 0;
  margin-left: 0.75rem; }
  .TeamGoalForm__labelContainer--18rLE[dir="rtl"],
  [dir="rtl"] .TeamGoalForm__labelContainer--18rLE {
    margin-right: 0.75rem;
    margin-left: 0; }

.TeamGoalForm__detailedDescriptionContainer--21-N4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%; }

.TeamGoalForm__detailedDescriptionButton--2Pe2X {
  margin-left: auto; }

.TeamGoalForm__labelText--2XfB2 {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1.125rem;
  line-height: 1.33333;
  position: relative;
  top: 0.30667em;
  margin-bottom: 0.75rem;
  margin-right: 0;
  margin-left: 0; }
  html:lang(he) .TeamGoalForm__labelText--2XfB2 {
    font-family: "Open Sans", Tahoma, sans-serif; }
  html:lang(ar) .TeamGoalForm__labelText--2XfB2 {
    font-family: "Open Sans", Tahoma, sans-serif; }
  html:lang(he) .TeamGoalForm__labelText--2XfB2 {
    font-family: "Ideal Sans A", "Ideal Sans B", Tahoma, sans-serif; }
  html:lang(ar) .TeamGoalForm__labelText--2XfB2 {
    font-family: "Ideal Sans A", "Ideal Sans B", Tahoma, sans-serif; }
  .TeamGoalForm__labelText--2XfB2[dir="rtl"],
  [dir="rtl"] .TeamGoalForm__labelText--2XfB2 {
    margin-right: 0;
    margin-left: 0; }

.TeamGoalForm__titleContainer--EkV8e {
  margin-bottom: 0.375rem;
  margin-right: 0;
  margin-left: 0; }
  .TeamGoalForm__titleContainer--EkV8e[dir="rtl"],
  [dir="rtl"] .TeamGoalForm__titleContainer--EkV8e {
    margin-right: 0;
    margin-left: 0; }

.TeamGoalForm__priorityContainer--2cGd1 {
  padding-top: 0.75rem;
  padding-right: 0;
  padding-left: 0; }
  .TeamGoalForm__priorityContainer--2cGd1[dir="rtl"],
  [dir="rtl"] .TeamGoalForm__priorityContainer--2cGd1 {
    padding-right: 0;
    padding-left: 0; }
