/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.styles__calendar--1EtMu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: #35374a; }

.styles__controls--_drow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.styles__weekdays--3uI7h {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-bottom: 0.75rem; }

.styles__row--3OiEW {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  border-bottom: 1px solid #d8dad9; }
  .styles__row--3OiEW:last-child {
    border-bottom: 0; }

.styles__dayName--XXEO4 {
  text-align: center;
  width: 42px; }

.styles__day--CM2QO {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71429;
  position: relative;
  top: 0.49714em;
  position: static;
  padding: 0;
  border-width: 0 1px;
  border-color: #d8dad9;
  border-style: solid;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 40px;
  outline: 0; }
  html:lang(he) .styles__day--CM2QO {
    font-family: "Open Sans", Tahoma, sans-serif; }
  html:lang(ar) .styles__day--CM2QO {
    font-family: "Open Sans", Tahoma, sans-serif; }
  html:lang(he) .styles__day--CM2QO {
    font-family: "Ideal Sans A", "Ideal Sans B", Tahoma, sans-serif; }
  html:lang(ar) .styles__day--CM2QO {
    font-family: "Ideal Sans A", "Ideal Sans B", Tahoma, sans-serif; }
  .styles__day--CM2QO:not(:first-child) {
    border-left-width: 0; }
  .styles__row--3OiEW:first-child > .styles__day--CM2QO:not(.styles__empty--1OOVa) {
    border-top-width: 1px; }
  .styles__empty--1OOVa + .styles__day--CM2QO {
    border-left-width: 1px; }
  .styles__row--3OiEW:last-child > .styles__day--CM2QO {
    border-bottom-width: 1px; }

.styles__between--1uIT4 {
  background-color: #d8dad9; }

.styles__selected--XK0YA {
  background-color: #21a38b;
  color: white;
  border-color: #21a38b; }

.styles__empty--1OOVa {
  border: none;
  padding-right: 0;
  padding-left: 1px; }
  .styles__empty--1OOVa[dir="rtl"],
  [dir="rtl"] .styles__empty--1OOVa {
    padding-right: 1px;
    padding-left: 0; }
