/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.Comment__container--US5zU {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row; }

.Comment__content--B_QWx {
  padding-right: 0;
  padding-left: 0.75rem;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1; }
  .Comment__content--B_QWx[dir="rtl"],
  [dir="rtl"] .Comment__content--B_QWx {
    padding-right: 0.75rem;
    padding-left: 0; }

.Comment__messageContainer--2yH9r {
  padding: 10px;
  background: #f6f6f6;
  border-radius: 3px;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  margin-right: 0;
  margin-left: 0; }
  .Comment__messageContainer--2yH9r[dir="rtl"],
  [dir="rtl"] .Comment__messageContainer--2yH9r {
    margin-right: 0;
    margin-left: 0; }

.Comment__message--2w4eP {
  margin-top: 0.75rem;
  margin-right: 0;
  margin-left: 0; }
  .Comment__message--2w4eP[dir="rtl"],
  [dir="rtl"] .Comment__message--2w4eP {
    margin-right: 0;
    margin-left: 0; }
