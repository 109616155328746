/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
.ToggleableProfileContent {
  position: relative;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  width: 50%;
  max-width: 600px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background: white;
}
@media (max-width: 992px) {
  .ToggleableProfileContent {
    display: none;
  }
}
.ToggleableProfileContent .ProfileContent .ProfileHeader {
  display: none;
}
.ToggleableProfileContent--toggle {
  color: #35374a;
  position: absolute;
  top: 20px;
  left: 10px;
  cursor: pointer;
  z-index: 2;
  opacity: 0.5;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.ToggleableProfileContent--toggle:hover {
  opacity: 1;
}
.ToggleableProfileContent--toggle-closed {
  left: -310px;
}
.ToggleableProfileContent .Tabs--tab {
  font-size: 12px;
  padding: 8px 4px;
}
.ToggleableProfileContent--panel-style {
  height: 100%;
}
.ToggleableProfileContent--panel-style .ProfileContent {
  padding: 60px;
  height: 100%;
  overflow-y: auto;
}
.ToggleableProfileContent--closed {
  width: 0;
  max-width: 0;
}
.ToggleableProfileContent--closed .ProfileContent {
  padding: 0;
}
.ToggleableProfileContent--tooltip {
  padding: 16px;
  max-width: 300px;
}
