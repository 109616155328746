/* iTheme */
.ui-rangeSlider {
  height: 30px;
  padding-top: 40px;
}
.ui-rangeSlider,
.ui-rangeSlider-container,
.ui-rangeSlider-arrow {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.ui-rangeSlider-withArrows .ui-rangeSlider-container {
  margin: 0 15px;
}
.ui-rangeSlider-withArrows .ui-rangeSlider-container,
.ui-rangeSlider-noArrow .ui-rangeSlider-container,
.ui-rangeSlider-arrow {
  -webkit-box-shadow: inset 0 4px 6px -2px RGBA(0, 0, 0, 0.5);
  box-shadow: inset 0 4px 6px -2px RGBA(0, 0, 0, 0.5);
}
.ui-rangeSlider-disabled.ui-rangeSlider-withArrows .ui-rangeSlider-container,
.ui-rangeSlider-disabled.ui-rangeSlider-noArrow .ui-rangeSlider-container,
.ui-rangeSlider-disabled .ui-rangeSlider-arrow {
  -webkit-box-shadow: inset 0 4px 6px -2px RGBA(0, 0, 0, 0.3);
  box-shadow: inset 0 4px 6px -2px RGBA(0, 0, 0, 0.3);
}
.ui-rangeSlider-noArrow .ui-rangeSlider-container {
  border-radius: 4px;
  border-left: solid 1px #515862;
  border-right: solid 1px #515862;
}
.ui-rangeSlider-disabled.ui-rangeSlider-noArrow .ui-rangeSlider-container {
  border-color: #8490a3;
}
.ui-rangeSlider-container,
.ui-rangeSlider-arrow {
  height: 30px;
  border-top: solid 1px #232a32;
  border-bottom: solid 1px #6a7179;
}
.ui-rangeSlider-disabled .ui-rangeSlider-container,
.ui-rangeSlider-disabled .ui-rangeSlider-arrow {
  border-top-color: #49576b;
  border-bottom-color: #9ca7b3;
}
.ui-rangeSlider-container,
.ui-rangeSlider-arrow,
.ui-rangeSlider-label {
  background: #67707f;
  background: -moz-linear-gradient(top, #67707f 0, #888da0 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #67707f),
    color-stop(100%, #888da0)
  );
}
.ui-rangeSlider-disabled .ui-rangeSlider-container,
.ui-rangeSlider-disabled .ui-rangeSlider-arrow,
.ui-rangeSlider-disabled .ui-rangeSlider-label {
  background: #95a4bd;
  background: -moz-linear-gradient(top, #95a4bd 0, #b2bbd8 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #95a4bd),
    color-stop(100%, #b2bbd8)
  );
}
.ui-rangeSlider-arrow {
  width: 14px;
  cursor: pointer;
}
.ui-rangeSlider-leftArrow {
  border-radius: 4px 0 0 4px;
  border-left: solid 1px #515862;
}
.ui-rangeSlider-disabled .ui-rangeSlider-leftArrow {
  border-left-color: #8792a2;
}
.ui-rangeSlider-rightArrow {
  border-radius: 0 4px 4px 0;
  border-right: solid 1px #515862;
}
.ui-rangeSlider-disabled .ui-rangeSlider-rightArrow {
  border-right-color: #8792a2;
}
.ui-rangeSlider-arrow-inner {
  position: absolute;
  top: 50%;
  border: 10px solid transparent;
  width: 0;
  height: 0;
  margin-top: -10px;
}
.ui-rangeSlider-leftArrow .ui-rangeSlider-arrow-inner {
  border-right: 10px solid #a4a8b7;
  left: 0;
  margin-left: -8px;
}
.ui-rangeSlider-leftArrow:hover .ui-rangeSlider-arrow-inner {
  border-right: 10px solid #b3b6c2;
}
.ui-rangeSlider-disabled .ui-rangeSlider-leftArrow .ui-rangeSlider-arrow-inner,
.ui-rangeSlider-disabled
  .ui-rangeSlider-leftArrow:hover
  .ui-rangeSlider-arrow-inner {
  border-right-color: #bbc0cf;
}
.ui-rangeSlider-rightArrow .ui-rangeSlider-arrow-inner {
  border-left: 10px solid #a4a8b7;
  right: 0;
  margin-right: -8px;
}
.ui-rangeSlider-rightArrow:hover .ui-rangeSlider-arrow-inner {
  border-left: 10px solid #b3b6c2;
}
.ui-rangeSlider-disabled .ui-rangeSlider-rightArrow .ui-rangeSlider-arrow-inner,
.ui-rangeSlider-disabled
  .ui-rangeSlider-rightArrow:hover
  .ui-rangeSlider-arrow-inner {
  border-left-color: #bbc0cf;
}
.ui-rangeSlider-innerBar {
  width: 110%;
  height: 100%;
  left: -10px;
  overflow: hidden;
}
.ui-rangeSlider-bar {
  background: #68a1d6;
  height: 29px;
  margin: 1px 0;
  border-radius: 4px;
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
  cursor: -moz-grab;
  -webkit-box-shadow: inset 0 2px 6px RGBA(0, 0, 0, 0.5);
  box-shadow: inset 0 2px 6px RGBA(0, 0, 0, 0.5);
}
.ui-rangeSlider-disabled .ui-rangeSlider-bar {
  background: #93aeca;
  -webkit-box-shadow: inset 0 2px 6px RGBA(0, 0, 0, 0.3);
  box-shadow: inset 0 2px 6px RGBA(0, 0, 0, 0.3);
}
.ui-rangeSlider-handle {
  width: 10px;
  height: 30px;
  background: transparent;
  cursor: col-resize;
}
.ui-rangeSlider-label {
  padding: 5px 10px;
  bottom: 40px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 0 #c2c5d6;
  box-shadow: 0 1px 0 #c2c5d6;
  color: #fff;
  font-size: 15px;
  cursor: col-resize;
}
.ui-rangeSlider-label-inner {
  position: absolute;
  top: 100%;
  left: 50%;
  display: block;
  z-index: 99;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  margin-left: -10px;
  border-top: 10px solid #888da0;
}
.ui-rangeSlider-disabled .ui-rangeSlider-label-inner {
  border-top-color: #b2bbd8;
}
.ui-editRangeSlider-inputValue {
  width: 2em;
  text-align: center;
  font-size: 15px;
}
.ui-rangeSlider .ui-ruler-scale {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.ui-rangeSlider .ui-ruler-tick {
  float: left;
}
.ui-rangeSlider .ui-ruler-scale0 .ui-ruler-tick-inner {
  color: #fff;
  margin-top: 1px;
  border-left: 1px solid #fff;
  height: 29px;
  padding-left: 2px;
  position: relative;
}
.ui-rangeSlider .ui-ruler-scale0 .ui-ruler-tick-label {
  position: absolute;
  bottom: 6px;
}
.ui-rangeSlider .ui-ruler-scale1 .ui-ruler-tick-inner {
  border-left: 1px solid #fff;
  margin-top: 25px;
  height: 5px;
}

#slider {
  margin-right: 256px;
}

@media (max-width: 640px) {
  #slider {
    margin-right: 0px;
  }
}

.ui-rangeSlider-withArrows .ui-rangeSlider-container,
.ui-rangeSlider-noArrow .ui-rangeSlider-container,
.ui-rangeSlider-arrow {
  -webkit-box-shadow: 0px 0px 3px RGBA(0, 0, 0, 0.3);
  box-shadow: 0px 0px 3px RGBA(0, 0, 0, 0.3);
}

.ui-rangeSlider-noArrow .ui-rangeSlider-container {
  border-radius: 0px;
  border-left: 0px;
  border-right: 0px;
}

.ui-rangeSlider-container,
.ui-rangeSlider-arrow {
  height: 30px;

  border-top: 0px;
  border-bottom: solid 1px #f3f3f3;
}

.ui-rangeSlider .ui-ruler-tick {
  float: left;
}

.ui-rangeSlider .ui-ruler-scale0 .ui-ruler-tick-inner {
  color: #979797;
  margin-top: 1px;
  border-left: 1px solid #cfcde4;
  height: 29px;
  padding-left: 2px;
  position: relative;
}

.ui-rangeSlider .ui-ruler-scale0 .ui-ruler-tick-label {
  position: absolute;
  bottom: 6px;
  font-size: 0.75em;
  opacity: 0.8;
}

.ui-rangeSlider .ui-ruler-scale1 .ui-ruler-tick-inner {
  border-left: 1px solid #d5d5d5;
  margin-top: 25px;
  height: 5px;
}

.ui-rangeSlider-container,
.ui-rangeSlider-arrow,
.ui-rangeSlider-label {
  background: #ffffff;
}

.ui-rangeSlider-leftArrow {
  border-radius: 0;
  border-left: solid 1px #c8c8c8;
}

.ui-rangeSlider-disabled .ui-rangeSlider-leftArrow {
  border-left-color: #8792a2;
}

.ui-rangeSlider-rightArrow {
  border-radius: 0;
  border-right: solid 1px #c8c8c8;
}

.ui-rangeSlider-disabled .ui-rangeSlider-rightArrow {
  border-right-color: #8792a2;
}

.ui-rangeSlider-label {
  padding: 5px 10px;
  bottom: 40px;
  border-radius: 4px;

  -webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.4);

  color: #bdbdbd;
  font-size: 15px;

  cursor: col-resize;
}

.ui-rangeSlider-label-inner {
  position: absolute;
  top: 100%;
  left: 50%;
  display: block;
  z-index: 99;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  margin-left: -10px;
  border-top: 10px solid #b6b6b6;
}

.ui-rangeSlider-leftArrow .ui-rangeSlider-arrow-inner {
  border-right: 10px solid #b6b6b6;
  left: 0;
  margin-left: -8px;
}

.ui-rangeSlider-rightArrow .ui-rangeSlider-arrow-inner {
  border-left: 10px solid #b6b6b6;
  right: 0;
  margin-right: -8px;
}

.ui-rangeSlider-handle {
  background: transparent;
}
.ui-rangeSlider-bar {
  margin-top: 0;
  height: 30px;
  background: transparent;
  border-radius: 0;
  -webkit-box-shadow: inset 0 0 25px rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 0 25px rgba(0, 0, 0, 0.2);
}
