/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.PrioritySelector__radio--3oL-v {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  max-width: 300px; }
  @media (max-width: 767px) {
    .PrioritySelector__radio--3oL-v {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-right: 0.75rem;
      padding-left: 0.75rem; }
      .PrioritySelector__radio--3oL-v[dir="rtl"],
      [dir="rtl"] .PrioritySelector__radio--3oL-v {
        padding-right: 0.75rem;
        padding-left: 0.75rem; } }
