/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
.TeamMemberCard__card--1FFMb {
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;
  margin-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #fff;
  position: relative;
  border: 1px solid #d8dad9;
  border-radius: 3px;
  padding: 1.5rem;
  width: 325px;
  height: 198px; }
  .TeamMemberCard__card--1FFMb[dir="rtl"],
  [dir="rtl"] .TeamMemberCard__card--1FFMb {
    margin-right: 0;
    margin-left: 1.5rem; }
  .TeamMemberCard__card--1FFMb:hover .TeamMemberCard__buttonsContainer--AkwD_ {
    opacity: 1; }
  @media (max-width: 767px) {
    .TeamMemberCard__card--1FFMb {
      width: 100%;
      margin-right: 0;
      margin-left: 0; }
      .TeamMemberCard__card--1FFMb[dir="rtl"],
      [dir="rtl"] .TeamMemberCard__card--1FFMb {
        margin-right: 0;
        margin-left: 0; } }

.TeamMemberCard__avatar--2pXWG {
  margin: 0.75rem; }

.TeamMemberCard__pill--2R_hI {
  top: 0.75rem;
  right: auto;
  left: 0.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 30px;
  border-radius: 15px;
  color: #35374a;
  background-color: #f0f1f4;
  padding: 0.375rem 0.75rem;
  position: absolute; }
  .TeamMemberCard__pill--2R_hI[dir="rtl"],
  [dir="rtl"] .TeamMemberCard__pill--2R_hI {
    right: 0.75rem;
    left: auto; }

.TeamMemberCard__buttonsContainer--AkwD_ {
  border-radius: 3px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  overflow: hidden; }
  .TeamMemberCard__buttonsContainer--AkwD_ > div {
    border-radius: 0;
    width: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0;
    text-align: left;
    opacity: 0.7;
    padding: 0 10px;
    overflow: hidden;
    -webkit-transition: width 0.2s ease;
    transition: width 0.2s ease;
    border-right: 1px solid #d8dad9; }
    .TeamMemberCard__buttonsContainer--AkwD_ > div:nth-of-type(1) {
      background-color: #f9f9fa;
      border-bottom: 1px solid #d8dad9; }
    .TeamMemberCard__buttonsContainer--AkwD_ > div:nth-of-type(2) {
      background-color: #f9f9fa;
      border-bottom: 1px solid #d8dad9; }
    .TeamMemberCard__buttonsContainer--AkwD_ > div:nth-of-type(3) {
      background-color: #f9f9fa; }
    .TeamMemberCard__buttonsContainer--AkwD_ > div:hover {
      border-right: 0px;
      opacity: 1;
      width: 100%;
      -webkit-box-shadow: none;
              box-shadow: none; }
