/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.RadioGroupRow__radioGroupLabel--LdVCC {
  margin-bottom: 0.75rem; }
  .ideal-sans .RadioGroupRow__radioGroupLabel--LdVCC label {
    padding-top: 0; }

.RadioGroupRow__radioGroupContainer--13Dj3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 0.75rem; }
  .RadioGroupRow__radioGroupContainer--13Dj3.RadioGroupRow__noBottomMargin--24Rdk {
    margin-bottom: 0; }
  .RadioGroupRow__radioGroupContainer--13Dj3 label {
    min-width: 60px; }
