/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.AlignableGoals__list--21zWu {
  margin-top: 1.5rem;
  margin-right: 0;
  margin-left: 0;
  min-height: 270px; }
  .AlignableGoals__list--21zWu[dir="rtl"],
  [dir="rtl"] .AlignableGoals__list--21zWu {
    margin-right: 0;
    margin-left: 0; }

.AlignableGoals__table--2MRth {
  max-height: 325px;
  min-height: 198px;
  overflow: scroll;
  border: 1px solid #d8dad9;
  border-radius: 3px;
  background-color: #ececef; }

.AlignableGoals__goal--3XeQU {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-right: 0.1875rem;
  padding-left: 0.75rem;
  background-color: white;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #d8dad9;
  border-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .AlignableGoals__goal--3XeQU[dir="rtl"],
  [dir="rtl"] .AlignableGoals__goal--3XeQU {
    padding-right: 0.75rem;
    padding-left: 0.1875rem; }
  .AlignableGoals__goal--3XeQU:last-child {
    border-bottom: 1px solid #d8dad9;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px; }
  .AlignableGoals__goal--3XeQU input {
    visibility: hidden; }

.AlignableGoals__checkboxContainer--EFuVh {
  width: 50%;
  margin-right: 0.75rem;
  margin-left: 0; }
  .AlignableGoals__checkboxContainer--EFuVh[dir="rtl"],
  [dir="rtl"] .AlignableGoals__checkboxContainer--EFuVh {
    margin-right: 0;
    margin-left: 0.75rem; }
  .AlignableGoals__checkboxContainer--EFuVh label {
    white-space: nowrap; }
    .AlignableGoals__checkboxContainer--EFuVh label span {
      text-overflow: ellipsis;
      overflow: hidden; }

.AlignableGoals__emptyState--121Pk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
  margin-right: 0;
  margin-left: 0; }
  .AlignableGoals__emptyState--121Pk[dir="rtl"],
  [dir="rtl"] .AlignableGoals__emptyState--121Pk {
    margin-right: 0;
    margin-left: 0; }
  @media (max-width: 767px) {
    .AlignableGoals__emptyState--121Pk {
      padding: 1.5rem; } }

.AlignableGoals__filter--21OE5 {
  -ms-flex-preferred-size: 200px;
      flex-basis: 200px; }
  .AlignableGoals__filter--21OE5:not(:last-child) {
    margin-right: 1.5rem;
    margin-left: 0; }
    .AlignableGoals__filter--21OE5:not(:last-child)[dir="rtl"],
    [dir="rtl"] .AlignableGoals__filter--21OE5:not(:last-child) {
      margin-right: 0;
      margin-left: 1.5rem; }
  @media (max-width: 767px) {
    .AlignableGoals__filter--21OE5 {
      width: 100%;
      -ms-flex-preferred-size: 4.5rem;
          flex-basis: 4.5rem; } }

.AlignableGoals__filterRight--12FDi {
  max-width: 500px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }
