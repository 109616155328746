.FeedbackItemTitle {
  margin-bottom: 5px;
}
.FeedbackItemTitle > * {
  margin-right: 10px;
}
.FeedbackItemTitle--reviewer-name {
  color: #35374a;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.FeedbackItemTitle--date {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
  color: #35374a;
  opacity: 0.7;
  margin-bottom: -5px;
}
.FeedbackItemTitle--type {
  color: #35374a;
  background-color: #ebebed;
  border-radius: 15px;
  height: 24px;
  padding: 3px 8px;
  margin-left: 10px;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 500;
  letter-spacing: 0.04166667em;
  font-size: 0.75rem;
  line-height: 2;
  position: relative;
  -webkit-font-smoothing: antialiased;
  line-height: 17px;
  font-weight: 400;
}
