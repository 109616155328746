/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
.Checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.Checkbox--label {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
  color: #35374a;
}
.Checkbox--label-selected {
  font-weight: 400;
}
.Checkbox--label-disabled {
  opacity: 0.3;
}
[type="checkbox"]:checked + label::before {
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icons/Informational/check</title><desc>Created with Sketch.</desc><defs><polygon id="path-1" points="8.33333333 14.3416667 4.16666667 10.175 5.34166667 9 8.33333333 11.9833333 14.6583333 5.65833333 15.8333333 6.84166667"></polygon></defs><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Icons/Informational/check"><mask id="mask-2" fill="white"></mask><use fill="%233e4543" xlink:href="%23path-1"></use></g></g></svg>');
  display: block;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid #e1e2ea;
  background-color: #fff;
  -webkit-transform: rotateZ(0deg);
          transform: rotateZ(0deg);
  z-index: 0;
}
[type="checkbox"].Checkbox--filled:checked + label::before {
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icons/Informational/check</title><desc>Created with Sketch.</desc><defs><polygon id="path-1" points="8.33333333 14.3416667 4.16666667 10.175 5.34166667 9 8.33333333 11.9833333 14.6583333 5.65833333 15.8333333 6.84166667"></polygon></defs><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Icons/Informational/check"><mask id="mask-2" fill="%2321A38B8"></mask><use fill="white" xlink:href="%23path-1"></use></g></g></svg>');
  display: block;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid #e1e2ea;
  background-color: #21a38b;
  -webkit-transform: rotateZ(0deg);
          transform: rotateZ(0deg);
  z-index: 0;
}
[type="checkbox"].Checkbox--filled:indeterminate + label::before {
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><polygon id="path-1" points="13.9962769 10.8333333 6.01080322 10.8333333 6.01080322 9.16666667 13.9962769 9.16666667"></polygon></defs><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="minus"><mask id="mask-2" fill="%2321A38B8"><use xlink:href="#path-1"></use></mask><use id="Icons/Informational/minus" fill="white" xlink:href="#path-1"></use></g></g></svg>');
  display: block;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid #e1e2ea;
  background-color: #21a38b;
  -webkit-transform: rotateZ(0deg);
          transform: rotateZ(0deg);
  z-index: 0;
}
[type="checkbox"]:indeterminate + label::before {
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><polygon id="path-1" points="13.9962769 10.8333333 6.01080322 10.8333333 6.01080322 9.16666667 13.9962769 9.16666667"></polygon></defs><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="minus"><mask id="mask-2" fill="white"><use xlink:href="#path-1"></use></mask><use id="Icons/Informational/minus" fill="%233e4543" xlink:href="#path-1"></use></g></g></svg>');
  display: block;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid #e1e2ea;
  background-color: #fff;
  -webkit-transform: rotateZ(0deg);
          transform: rotateZ(0deg);
  z-index: 0;
}
[type="checkbox"]:indeterminate:disabled + label::before {
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><polygon id="path-1" points="13.9962769 10.8333333 6.01080322 10.8333333 6.01080322 9.16666667 13.9962769 9.16666667"></polygon></defs><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="minus"><mask id="mask-2" fill="white"><use xlink:href="#path-1"></use></mask><use id="Icons/Informational/minus" fill="%233e4543" xlink:href="#path-1"></use></g></g></svg>');
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.19) inset;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.19) inset;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  cursor: default;
}
[type="checkbox"]:not(:checked) + label::before {
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 0 0;
          box-shadow: 0 0 0 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 0;
}
[type="checkbox"]:active:hover + label::before {
  background-color: rgba(255, 255, 255, 0.3);
}
[type="checkbox"]:focus:not(:hover) + label::before {
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 2px solid #0168b3;
  border-radius: 2px;
  display: block;
}
[type="checkbox"]:focus:not(:hover):checked + label::before {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 2px solid #0168b3;
  border-radius: 2px;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg width="20px" height="20px" viewBox="1 1 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icons/Informational/check</title><desc>Created with Sketch.</desc><defs><polygon id="path-1" points="8.33333333 14.3416667 4.16666667 10.175 5.34166667 9 8.33333333 11.9833333 14.6583333 5.65833333 15.8333333 6.84166667"></polygon></defs><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Icons/Informational/check"><mask id="mask-2" fill="white"></mask><use fill="%233e4543" xlink:href="%23path-1"></use></g></g></svg>');
  display: block;
}
[type="checkbox"]:hover + label::before {
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09) inset;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09) inset;
  border-radius: 2px;
}
[type="checkbox"]:disabled:not(:checked) + label::before {
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.19) inset;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.19) inset;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  cursor: default;
}
[type="checkbox"]:disabled:checked + label::before {
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.19) inset;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.19) inset;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  cursor: default;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icons/Informational/check</title><desc>Created with Sketch.</desc><defs><polygon id="path-1" points="8.33333333 14.3416667 4.16666667 10.175 5.34166667 9 8.33333333 11.9833333 14.6583333 5.65833333 15.8333333 6.84166667"></polygon></defs><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Icons/Informational/check"><mask id="mask-2" fill="white"></mask><use fill="%2397A4AB"  xlink:href="%23path-1"></use></g></g></svg>');
  display: block;
  -webkit-transform: rotateZ(0deg);
          transform: rotateZ(0deg);
}
