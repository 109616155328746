/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
.GoalRow__goalInfo--3qVzA {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%; }

.GoalRow__priorityAndStatus--3Y5qV {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 1.5rem;
  padding-right: 0;
  padding-left: 0; }
  .GoalRow__priorityAndStatus--3Y5qV[dir="rtl"],
  [dir="rtl"] .GoalRow__priorityAndStatus--3Y5qV {
    padding-right: 0;
    padding-left: 0; }
  @media (max-width: 767px) {
    .GoalRow__priorityAndStatus--3Y5qV {
      padding-top: 0;
      padding-right: 0;
      padding-left: 0; }
      .GoalRow__priorityAndStatus--3Y5qV[dir="rtl"],
      [dir="rtl"] .GoalRow__priorityAndStatus--3Y5qV {
        padding-right: 0;
        padding-left: 0; } }

.GoalRow__status--3vIWt {
  padding-right: 0;
  padding-left: 0.75rem; }
  .GoalRow__status--3vIWt[dir="rtl"],
  [dir="rtl"] .GoalRow__status--3vIWt {
    padding-right: 0.75rem;
    padding-left: 0; }

.GoalRow__complete--3VTGy {
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.GoalRow__progressBar--3Dn1x {
  height: 1.5rem;
  width: calc(100% - 3rem); }

.GoalRow__container--1EMyN {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%; }

.GoalRow__openIcon--3Iidn {
  color: #898ba9;
  width: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  @media (max-width: 767px) {
    .GoalRow__openIcon--3Iidn {
      display: none; } }

.GoalRow__mobileContainer--38WI5 {
  padding: 1.5rem; }

.GoalRow__mobileMargins--3uzbU {
  margin-bottom: 0.75rem;
  margin-right: 0;
  margin-left: 0; }
  .GoalRow__mobileMargins--3uzbU[dir="rtl"],
  [dir="rtl"] .GoalRow__mobileMargins--3uzbU {
    margin-right: 0;
    margin-left: 0; }
