/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
.GoalsSummaryCell__container--3x31g {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-right: 0;
  padding-left: 0; }
  .GoalsSummaryCell__container--3x31g[dir="rtl"],
  [dir="rtl"] .GoalsSummaryCell__container--3x31g {
    padding-right: 0;
    padding-left: 0; }
  @media (max-width: 767px) {
    .GoalsSummaryCell__container--3x31g {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-right: 0.75rem;
      padding-left: 0.75rem; }
      .GoalsSummaryCell__container--3x31g[dir="rtl"],
      [dir="rtl"] .GoalsSummaryCell__container--3x31g {
        padding-right: 0.75rem;
        padding-left: 0.75rem; } }

.GoalsSummaryCell__progress--2xfFy {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .GoalsSummaryCell__progress--2xfFy div {
    padding: 0; }
  @media (max-width: 767px) {
    .GoalsSummaryCell__progress--2xfFy {
      width: 100%;
      margin-bottom: 0.75rem;
      margin-right: 0;
      margin-left: 0; }
      .GoalsSummaryCell__progress--2xfFy[dir="rtl"],
      [dir="rtl"] .GoalsSummaryCell__progress--2xfFy {
        margin-right: 0;
        margin-left: 0; } }

@media (max-width: 767px) {
  .GoalsSummaryCell__teamName--3xX1h {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .GoalsSummaryCell__teamName--3xX1h[dir="rtl"],
    [dir="rtl"] .GoalsSummaryCell__teamName--3xX1h {
      margin-right: 0;
      margin-left: 0; } }

@media (max-width: 767px) {
  .GoalsSummaryCell__goalsCount--3FHnV {
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; } }

.GoalsSummaryCell__chevronIcon--7FAIp {
  color: #898ba9;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }
  @media (max-width: 767px) {
    .GoalsSummaryCell__chevronIcon--7FAIp {
      position: absolute;
      top: 0.75rem;
      right: 0.75rem; } }
