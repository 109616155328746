/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
/**
 * Styles a container such that `@spacing` separates each item in the given `@direction`.
 *
 * Note that this mixin sets margin styles in the given direction on the container and on the items.
 * For example, with `item-spacing(horizontal, 10px)`, you'll have `margin-left` and `margin-right`
 * values on the container and on the items (rendered as `& > *`).
 *
 *
 * Parameters:
 *
 * - `@direction`: keyword. horizontal|vertical
 * - `@spacing`: length or percentage. Any numeric value that can be used as a `margin` should work.
 *
 * Usage:
 *
 *    .item-spacing(vertical; 5px);
 *    .item-spacing(horizontal; 10px);
 */
.CompensationAnswersModal {
  padding-bottom: 80px;
}
.CompensationAnswersModal .CompensationAnswersModal--button-row {
  z-index: 10;
  position: fixed;
  width: 100%;
  bottom: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(80%, white), to(white));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 80%, white 100%);
  /* one button */
  /* two buttons */
}
.CompensationAnswersModal .CompensationAnswersModal--button-row .Button--secondary {
  background-color: white;
}
.CompensationAnswersModal .CompensationAnswersModal--button-row .Button {
  font-size: 15px;
  margin-left: 10px;
  margin-right: 10px;
}
.CompensationAnswersModal .CompensationAnswersModal--button-row .Button:first-child:nth-last-child(1) {
  min-width: 200px;
}
.CompensationAnswersModal .CompensationAnswersModal--button-row .Button:first-child:nth-last-child(2),
.CompensationAnswersModal .CompensationAnswersModal--button-row .Button:first-child:nth-last-child(2) ~ .Button {
  min-width: 150px;
}
.CompensationAnswersModal,
.CompensationAnswersModal p {
  font-size: 18px;
  line-height: 26px;
  color: #35374a;
}
.CompensationAnswersModal--reviewee-name-deactivated {
  color: rgba(53, 55, 74, 0.8);
  font-weight: 400;
  margin-left: 5px;
}
.CompensationAnswersModal .container.Modal--content {
  max-width: 750px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: visible !important;
}
@media (max-width: 900px) {
  .CompensationAnswersModal .container.Modal--content {
    max-width: 650px;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .CompensationAnswersModal .container.Modal--content {
    height: 90%;
  }
}
@supports (-ms-high-contrast-adjust: auto) {
  .CompensationAnswersModal .container.Modal--content {
    height: auto;
  }
}
.CompensationAnswersModal h5 {
  font-size: 30px;
}
.CompensationAnswersModal .subheading {
  color: rgba(53, 55, 74, 0.8);
}
.CompensationAnswersModal h6 {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font-weight: 600;
  margin: 0 0 25px;
  text-transform: none;
}
.CompensationAnswersModal strong {
  font-weight: 600;
}
.CompensationAnswersModal hr {
  margin: 30px 0;
}
.CompensationAnswersModal fieldset {
  border: 0 none;
  padding: 0;
  margin: 0 auto;
  width: 90%;
}
@media (max-width: 900px) {
  .CompensationAnswersModal fieldset {
    width: 95%;
  }
}
.CompensationAnswersModal .TextEditor,
.CompensationAnswersModal .TextEditor .DraftEditor-root,
.CompensationAnswersModal .TextEditor .public-DraftEditor-content,
.CompensationAnswersModal .TextEditor textarea {
  min-height: 90px;
}
.CompensationAnswersModal .RichTextEditor,
.CompensationAnswersModal textarea {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #e1e2ea;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 15px;
  font-size: inherit;
  margin-bottom: 0;
}
.CompensationAnswersModal .RichTextEditor--focused,
.CompensationAnswersModal textarea:focus {
  border-color: #21a38b;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.CompensationAnswersModal .ResponsiveWrapper--container {
  max-width: 900px;
}
.CompensationAnswersModal .LongAnswer--feedback-btn {
  color: #21a38b;
}
.CompensationAnswersModal--content {
  position: relative;
}
.CompensationAnswersModal--button-row {
  left: 0;
}
