/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.GoalOwnersDetails__container--3jScy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.GoalOwnersDetails__row--3JhRR {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 15px;
  margin-right: 0;
  margin-left: 0; }
  .GoalOwnersDetails__row--3JhRR[dir="rtl"],
  [dir="rtl"] .GoalOwnersDetails__row--3JhRR {
    margin-right: 0;
    margin-left: 0; }

.GoalOwnersDetails__item--2GNCK {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-bottom: 5px;
  margin-right: 0;
  margin-left: 0; }
  .GoalOwnersDetails__item--2GNCK[dir="rtl"],
  [dir="rtl"] .GoalOwnersDetails__item--2GNCK {
    margin-right: 0;
    margin-left: 0; }
