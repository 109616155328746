/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.Tiles__tiles--UogcO {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.Tiles__tile--329q9 {
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;
  margin-left: 0;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 322px;
  height: 262px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: 1px solid #d8dad9;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  text-align: left; }
  .Tiles__tile--329q9[dir="rtl"],
  [dir="rtl"] .Tiles__tile--329q9 {
    margin-right: 0;
    margin-left: 1.5rem; }
  @media (max-width: 767px) {
    .Tiles__tile--329q9 {
      width: 100%;
      margin-right: 0;
      margin-left: 0; }
      .Tiles__tile--329q9[dir="rtl"],
      [dir="rtl"] .Tiles__tile--329q9 {
        margin-right: 0;
        margin-left: 0; } }

.Tiles__content--1xROw {
  padding: 1.5rem;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  color: #fff;
  position: relative; }

.Tiles__seedling--2WI2U {
  background-color: #21a38b; }

.Tiles__wisteria--2ZZNS {
  background-color: #4b4d68; }

.Tiles__lapis--11yUw {
  background-color: #35374a; }

.Tiles__description--1teAB {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  top: 0.39em;
  opacity: 0;
  margin: 0;
  max-height: 4.5rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; }
  html:lang(he) .Tiles__description--1teAB {
    font-family: "Open Sans", Tahoma, sans-serif; }
  html:lang(ar) .Tiles__description--1teAB {
    font-family: "Open Sans", Tahoma, sans-serif; }
  html:lang(he) .Tiles__description--1teAB {
    font-family: "Ideal Sans A", "Ideal Sans B", Tahoma, sans-serif; }
  html:lang(ar) .Tiles__description--1teAB {
    font-family: "Ideal Sans A", "Ideal Sans B", Tahoma, sans-serif; }
  .Tiles__tile--329q9:hover .Tiles__description--1teAB {
    -webkit-animation-name: Tiles__custom-1--3Lc1Z;
            animation-name: Tiles__custom-1--3Lc1Z;
    -webkit-animation-name: Tiles__fade-0-to-1--1Sls8;
            animation-name: Tiles__fade-0-to-1--1Sls8;
    -webkit-animation-duration: 400ms;
            animation-duration: 400ms;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245);
            animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245); }

@-webkit-keyframes Tiles__custom-1--3Lc1Z {
  0% {
    opacity: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); } }

@keyframes Tiles__custom-1--3Lc1Z {
  0% {
    opacity: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); } }

@-webkit-keyframes Tiles__fade-0-to-1--1Sls8 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes Tiles__fade-0-to-1--1Sls8 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.Tiles__header--1SbcK {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.Tiles__heading--31OSI {
  margin-bottom: 0.375rem;
  margin-right: 0;
  margin-left: 0; }
  .Tiles__heading--31OSI[dir="rtl"],
  [dir="rtl"] .Tiles__heading--31OSI {
    margin-right: 0;
    margin-left: 0; }
  .Tiles__heading--31OSI > * {
    text-align: left; }

.Tiles__short--1uRwt {
  width: 60%; }

.Tiles__illustration--Q7ZmH {
  position: absolute;
  width: 40%;
  right: 1.5rem; }
  .Tiles__tile--329q9:hover .Tiles__illustration--Q7ZmH {
    opacity: 0; }

.Tiles__buttonMargin--3Jb2w {
  margin-right: 0.375rem;
  margin-left: 0; }
  .Tiles__buttonMargin--3Jb2w[dir="rtl"],
  [dir="rtl"] .Tiles__buttonMargin--3Jb2w {
    margin-right: 0;
    margin-left: 0.375rem; }

.Tiles__actions--38ihe {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 0.75rem;
  background: #fff;
  height: 4.5rem; }
