.GoalsTable .SearchField {
  background-color: white;
}
.GoalsTable--date-range-subheader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  padding-top: 10px;
}
.GoalsTable--date-range-subheader .flatpickr-input {
  text-align: center;
  max-width: 280px;
}
.GoalsTable--search-results {
  margin-bottom: 40px;
}
.GoalsTable--search-results .Table--header-cell {
  white-space: nowrap;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.GoalsTable--search-results .Table--body-cell {
  vertical-align: middle;
  max-width: 400px;
}
.GoalsTable--priority-cell {
  max-height: 100%;
}
.GoalsTable--owner-cell {
  overflow: hidden;
}
.GoalsTable--due-date-cell,
.GoalsTable--comment-indicator {
  width: 1%;
  white-space: nowrap;
}
.GoalsTable--goal-name {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
  color: #35374a;
}
.GoalsTable--goal-name-mobile {
  display: none;
  color: #35374a;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 8px;
}
.GoalsTable--owner-name-row {
  text-align: left;
}
.GoalsTable--owner-info {
  text-align: left;
}
@media (max-width: 500px) {
  .GoalsTable--goal-name {
    display: none;
  }
  .GoalsTable--goal-name-mobile {
    display: block;
  }
}
.GoalsTable--filter-bar {
  padding-top: 10px;
}
.GoalsTable--owner-bame {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.GoalsTable--owner-cell-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.GoalsTable--owner-info {
  margin-left: 15px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
