/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.UserAvatarNameAndRole__user--1-lvL {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.UserAvatarNameAndRole__avatar--1Yvfp {
  margin-right: 0.75rem;
  margin-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .UserAvatarNameAndRole__avatar--1Yvfp[dir="rtl"],
  [dir="rtl"] .UserAvatarNameAndRole__avatar--1Yvfp {
    margin-right: 0;
    margin-left: 0.75rem; }
  .UserAvatarNameAndRole__small--2oQ6l .UserAvatarNameAndRole__avatar--1Yvfp {
    margin-right: 1.125rem;
    margin-left: 0; }
    .UserAvatarNameAndRole__small--2oQ6l .UserAvatarNameAndRole__avatar--1Yvfp[dir="rtl"],
    [dir="rtl"] .UserAvatarNameAndRole__small--2oQ6l .UserAvatarNameAndRole__avatar--1Yvfp {
      margin-right: 0;
      margin-left: 1.125rem; }

.UserAvatarNameAndRole__small--2oQ6l .UserAvatarNameAndRole__content--B0fGV {
  margin-top: -0.375rem;
  margin-right: 0;
  margin-left: 0; }
  .UserAvatarNameAndRole__small--2oQ6l .UserAvatarNameAndRole__content--B0fGV[dir="rtl"],
  [dir="rtl"] .UserAvatarNameAndRole__small--2oQ6l .UserAvatarNameAndRole__content--B0fGV {
    margin-right: 0;
    margin-left: 0; }

.UserAvatarNameAndRole__small--2oQ6l .UserAvatarNameAndRole__name--An3jW {
  margin-bottom: -0.375rem;
  margin-right: 0;
  margin-left: 0; }
  .UserAvatarNameAndRole__small--2oQ6l .UserAvatarNameAndRole__name--An3jW[dir="rtl"],
  [dir="rtl"] .UserAvatarNameAndRole__small--2oQ6l .UserAvatarNameAndRole__name--An3jW {
    margin-right: 0;
    margin-left: 0; }
