.BaseEditableQuestion--container {
  border-radius: 3px;
  border: 1px solid #e1e2ea;
}
.BaseEditableQuestion--container > :last-child {
  border-radius: 0 0 3px 3px;
}
.BaseEditableQuestion--title-field .RichTextEditor {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1.125rem;
  line-height: 1.33333333;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.BaseEditableQuestion--description-field .RichTextEditor,
.BaseEditableQuestion--description-field .public-DraftEditorPlaceholder-root {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif !important;
  font-weight: 300 !important;
  letter-spacing: normal !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  position: relative !important;
  -webkit-font-smoothing: antialiased !important;
}
.BaseEditableQuestion--description-field.TextEditor .TextEditor--editor {
  padding-top: 0;
}
.BaseEditableQuestion--description-field .megadraft-editor .DraftEditor-root {
  font-size: 16px;
}
.BaseEditableQuestion--optional-slider {
  padding-top: 10px;
}
.BaseEditableQuestion--prompt-editor {
  position: relative;
  border-top: 1px solid #e1e2ea;
  padding: 15px;
  background: #fff;
}
.BaseEditableQuestion--prompt-editor,
.BaseEditableQuestion--prompt-editor textarea {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.BaseEditableQuestion--prompt-editor textarea {
  padding: 0;
  height: auto;
  min-height: 0;
  margin-left: -5px;
}
.BaseEditableQuestion--prompt-editor .InputClearButton {
  position: absolute;
  top: 0;
  right: 0;
}
.BaseEditableQuestion--prompt-label {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 5px;
}
.BaseEditableQuestion--prompt-field {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.BaseEditableQuestion button:focus,
.BaseEditableQuestion button:active {
  background-color: transparent;
}
