/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.UserCard__card--1zoFW {
  background-color: #fff;
  border: 1px solid #d8dad9;
  border-bottom: 0;
  padding: 0.375rem 0.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .UserCard__card--1zoFW:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
  .UserCard__card--1zoFW:last-child {
    border-bottom: 1px solid #d8dad9;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    margin-bottom: 1.5rem;
    margin-right: 0;
    margin-left: 0; }
    .UserCard__card--1zoFW:last-child[dir="rtl"],
    [dir="rtl"] .UserCard__card--1zoFW:last-child {
      margin-right: 0;
      margin-left: 0; }
