/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
.GoalsList__container--3VCbP {
  margin-bottom: 1.5rem;
  margin-right: 0;
  margin-left: 0; }
  .GoalsList__container--3VCbP[dir="rtl"],
  [dir="rtl"] .GoalsList__container--3VCbP {
    margin-right: 0;
    margin-left: 0; }

.GoalsList__header--1mqgg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  @media (max-width: 767px) {
    .GoalsList__header--1mqgg {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
          -ms-flex-direction: column-reverse;
              flex-direction: column-reverse; } }
