/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.GoalComments__commentsContainer--OjlmC {
  margin-top: 0.75rem;
  margin-right: 0;
  margin-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .GoalComments__commentsContainer--OjlmC[dir="rtl"],
  [dir="rtl"] .GoalComments__commentsContainer--OjlmC {
    margin-right: 0;
    margin-left: 0; }

.GoalComments__comments--2N9I5 {
  margin-top: 0.75rem;
  margin-right: 0;
  margin-left: 0;
  overflow-y: auto;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }
  .GoalComments__comments--2N9I5[dir="rtl"],
  [dir="rtl"] .GoalComments__comments--2N9I5 {
    margin-right: 0;
    margin-left: 0; }

.GoalComments__mainMessage--196yD {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0; }

.GoalComments__commentThread--2PsRC:not(:first-child) {
  margin-top: 1.5rem;
  margin-right: 0;
  margin-left: 0; }
  .GoalComments__commentThread--2PsRC:not(:first-child)[dir="rtl"],
  [dir="rtl"] .GoalComments__commentThread--2PsRC:not(:first-child) {
    margin-right: 0;
    margin-left: 0; }

.GoalComments__commentActions--1SzVu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

.GoalComments__textFieldContainer--2Gial {
  margin-bottom: 0.75rem;
  margin-right: 0;
  margin-left: 0; }
  .GoalComments__textFieldContainer--2Gial[dir="rtl"],
  [dir="rtl"] .GoalComments__textFieldContainer--2Gial {
    margin-right: 0;
    margin-left: 0; }
