/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
/**
 * Styles a container such that `@spacing` separates each item in the given `@direction`.
 *
 * Note that this mixin sets margin styles in the given direction on the container and on the items.
 * For example, with `item-spacing(horizontal, 10px)`, you'll have `margin-left` and `margin-right`
 * values on the container and on the items (rendered as `& > *`).
 *
 *
 * Parameters:
 *
 * - `@direction`: keyword. horizontal|vertical
 * - `@spacing`: length or percentage. Any numeric value that can be used as a `margin` should work.
 *
 * Usage:
 *
 *    .item-spacing(vertical; 5px);
 *    .item-spacing(horizontal; 10px);
 */
.Toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.Toggle input {
  opacity: 0;
}
.Toggle label {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
  color: #35374a;
}
.Toggle label.active {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.Toggle--container {
  display: inline-block;
  width: 50px;
  height: 30px;
  border-radius: 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  border: 1px solid rgba(53, 55, 74, 0.1);
  background-color: #f6f6f6;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  margin-left: 9px;
  margin-right: 9px;
}
.Toggle--container.active {
  background-color: #21a38b;
}
.Toggle--container.focused {
  border: 2px solid #0168b3;
}
.Toggle--handle {
  display: inline-block;
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 22px;
  -webkit-box-shadow: 0 1px 3px 0 #fff;
          box-shadow: 0 1px 3px 0 #fff;
  position: absolute;
  top: 3px;
  left: 4px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.Toggle--handle.active {
  left: 100%;
  -webkit-transform: translateX(-26px);
          transform: translateX(-26px);
}
.Toggle--handle.focused {
  top: 1px;
  left: 2px;
}
.Toggle--handle.active.focused {
  top: 2px;
  left: 100%;
  -webkit-transform: translateX(-25px);
          transform: translateX(-25px);
}
