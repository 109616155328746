/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.KeyResultList__iconContainer--_-VSK {
  -webkit-animation-name: KeyResultList__fade-0-to-1--2zZ1k;
          animation-name: KeyResultList__fade-0-to-1--2zZ1k;
  -webkit-animation-duration: 400ms;
          animation-duration: 400ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 3.75rem;
  height: 3rem;
  border: 1px solid #d8dad9;
  background-color: #ececef; }

@-webkit-keyframes KeyResultList__fade-0-to-1--2zZ1k {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes KeyResultList__fade-0-to-1--2zZ1k {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.KeyResultList__titleContainer--2eJgC {
  margin-bottom: 0.375rem;
  margin-right: 0;
  margin-left: 0; }
  .KeyResultList__titleContainer--2eJgC[dir="rtl"],
  [dir="rtl"] .KeyResultList__titleContainer--2eJgC {
    margin-right: 0;
    margin-left: 0; }
