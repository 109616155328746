/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
.TeamSummaryDetail__team--_hLDq {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-right: 1.5rem;
  padding-left: 0; }
  .TeamSummaryDetail__team--_hLDq[dir="rtl"],
  [dir="rtl"] .TeamSummaryDetail__team--_hLDq {
    padding-right: 0;
    padding-left: 1.5rem; }

.TeamSummaryDetail__header--fXT_m {
  margin-bottom: 0.75rem;
  margin-right: 0;
  margin-left: 0; }
  .TeamSummaryDetail__header--fXT_m[dir="rtl"],
  [dir="rtl"] .TeamSummaryDetail__header--fXT_m {
    margin-right: 0;
    margin-left: 0; }

.TeamSummaryDetail__createButton--Sx4zN {
  margin-bottom: 0.375rem;
  margin-right: 0;
  margin-left: 0;
  padding: 1.5rem;
  padding-top: 0; }
  .TeamSummaryDetail__createButton--Sx4zN[dir="rtl"],
  [dir="rtl"] .TeamSummaryDetail__createButton--Sx4zN {
    margin-right: 0;
    margin-left: 0; }

.TeamSummaryDetail__topSpace--3uRUu {
  margin-top: 0.75rem;
  margin-right: 0;
  margin-left: 0; }
  .TeamSummaryDetail__topSpace--3uRUu[dir="rtl"],
  [dir="rtl"] .TeamSummaryDetail__topSpace--3uRUu {
    margin-right: 0;
    margin-left: 0; }
