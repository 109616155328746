.ProfileItemVisibilityIndicator {
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  opacity: 0.5;
  cursor: default;
}
.ProfileItemVisibilityIndicator:hover {
  opacity: 1;
}
.ProfileItemVisibilityIndicator--tooltip {
  padding: 10px;
  font-weight: normal;
}
