/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
.SearchField {
  height: 54px;
  border: 1px solid #e1e2ea;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.SearchField input {
  border: 0;
  margin: 0;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.SearchField input::-webkit-input-placeholder {
  color: #35374a;
}
.SearchField input::-moz-placeholder {
  color: #35374a;
}
.SearchField input::-ms-input-placeholder {
  color: #35374a;
}
.SearchField input::placeholder {
  color: #35374a;
}
.SearchField input,
.SearchField input:focus {
  border: 0 none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.SearchField .icon,
.SearchField button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.SearchField button:hover {
  color: #35374a;
}
.SearchField > .icon,
.SearchField button {
  color: #35374a;
  margin: 0 12px 0 8px;
  padding: 0;
  opacity: 0.5;
}
.SearchField > .icon.active,
.SearchField button.active {
  opacity: 1;
}
.SearchField--full-width {
  width: 100%;
}
.SearchField.focused {
  border-color: #0168b3;
  border-width: 2px;
}
.SearchField.focused > .icon {
  color: #35374a;
  opacity: 1;
}
