/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
/**
 * Styles a container such that `@spacing` separates each item in the given `@direction`.
 *
 * Note that this mixin sets margin styles in the given direction on the container and on the items.
 * For example, with `item-spacing(horizontal, 10px)`, you'll have `margin-left` and `margin-right`
 * values on the container and on the items (rendered as `& > *`).
 *
 *
 * Parameters:
 *
 * - `@direction`: keyword. horizontal|vertical
 * - `@spacing`: length or percentage. Any numeric value that can be used as a `margin` should work.
 *
 * Usage:
 *
 *    .item-spacing(vertical; 5px);
 *    .item-spacing(horizontal; 10px);
 */
.SurveyForm {
  max-width: 800px;
  width: 100%;
}
.SurveyForm .TextEditor,
.SurveyForm .TextEditor .DraftEditor-root,
.SurveyForm .TextEditor .public-DraftEditor-content,
.SurveyForm .TextEditor textarea {
  min-height: 120px;
}
.SurveyForm .QuestionFields .RichTextEditor,
.SurveyForm .QuestionFields textarea {
  text-align: left;
}
.SurveyForm--share-reviewee {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 18px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.SurveyForm--submit-message {
  text-align: center;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.SurveyForm--submit {
  margin: 0px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.SurveyForm--submit-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
