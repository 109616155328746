/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.GoalsApp__goals--1V8-M {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  height: 100vh; }
  .GoalsApp__goals--1V8-M h1,
  .GoalsApp__goals--1V8-M h2,
  .GoalsApp__goals--1V8-M h3,
  .GoalsApp__goals--1V8-M h4,
  .GoalsApp__goals--1V8-M h5 {
    text-align: inherit; }
  .GoalsApp__goals--1V8-M select {
    display: initial; }

.GoalsApp__content--1EeK7 {
  padding: 20px 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  height: 100%;
  overflow-y: scroll; }
  @media (max-width: 767px) {
    .GoalsApp__content--1EeK7 {
      padding-top: 0.75rem;
      padding-bottom: 4.5rem;
      padding-right: 0.75rem;
      padding-left: 0.75rem; }
      .GoalsApp__content--1EeK7[dir="rtl"],
      [dir="rtl"] .GoalsApp__content--1EeK7 {
        padding-right: 0.75rem;
        padding-left: 0.75rem; } }

.GoalsApp__dossier--3NQ_w {
  width: 600px;
  height: 100%;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  background: #ffffff; }
  @media (max-width: 1023px) {
    .GoalsApp__dossier--3NQ_w {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 400; } }
  @media (max-width: 767px) {
    .GoalsApp__dossier--3NQ_w {
      display: none; } }
