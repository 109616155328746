.FadeoutTruncated {
  position: relative;
  overflow: hidden;
}
.FadeoutTruncated:not(.FadeoutTruncated--underflowed)::after {
  pointer-events: none;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 50%;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, white), to(rgba(255, 255, 255, 0)));
  background: linear-gradient(0deg, white 0, rgba(255, 255, 255, 0) 100%);
}
