/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.TeamForm__row--1fEp- {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly; }

.TeamForm__field--2ROHA {
  margin-right: 0.75rem;
  margin-left: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0; }
  .TeamForm__field--2ROHA[dir="rtl"],
  [dir="rtl"] .TeamForm__field--2ROHA {
    margin-right: 0;
    margin-left: 0.75rem; }
  .TeamForm__field--2ROHA:last-child {
    margin-right: 0;
    margin-left: 0; }
    .TeamForm__field--2ROHA:last-child[dir="rtl"],
    [dir="rtl"] .TeamForm__field--2ROHA:last-child {
      margin-right: 0;
      margin-left: 0; }

.TeamForm__large--2wHJV {
  -webkit-box-flex: 5;
      -ms-flex-positive: 5;
          flex-grow: 5; }

.TeamForm__small--38tQu {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

.TeamForm__actions--2dh_h {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

.TeamForm__action--2B2Mf {
  margin-right: 0.75rem;
  margin-left: 0; }
  .TeamForm__action--2B2Mf[dir="rtl"],
  [dir="rtl"] .TeamForm__action--2B2Mf {
    margin-right: 0;
    margin-left: 0.75rem; }
  .TeamForm__action--2B2Mf:last-child {
    margin-right: 0;
    margin-left: 0; }
    .TeamForm__action--2B2Mf:last-child[dir="rtl"],
    [dir="rtl"] .TeamForm__action--2B2Mf:last-child {
      margin-right: 0;
      margin-left: 0; }

.TeamForm__selectField--1TdcW {
  display: block; }
