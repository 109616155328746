.DataTable .Table--header-cell {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  max-width: 250px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  white-space: nowrap;
  z-index: 1;
  -webkit-box-shadow: 0px 2px 1px -2px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 2px 1px -2px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
}
.DataTable--header-tooltip {
  padding: 16px;
  max-width: 300px;
  color: #35374a;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.DataTable--header-tooltip :hover {
  color: #0168b3;
  cursor: pointer;
}
.DataTable--header-text .MarkdownText {
  white-space: nowrap;
}
.DataTable .Table--body-row {
  color: #35374a;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.DataTable .Table--body-cell {
  max-width: 250px;
  word-wrap: break-word;
}
.DataTable .Table--body-cell .FadeoutTruncated {
  max-height: 200px;
}
.DataTable--fixed-column {
  left: 0;
}
.DataTable--fixed-column.Table--header-cell {
  z-index: 3;
  -webkit-box-shadow: 1px 1px 0px 0px #e1e2ea !important;
          box-shadow: 1px 1px 0px 0px #e1e2ea !important;
}
.DataTable--fixed-column.Table--body-cell {
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
  background-color: white;
  -webkit-box-shadow: 2px 0px 1px -2px rgba(0, 0, 0, 0.2);
          box-shadow: 2px 0px 1px -2px rgba(0, 0, 0, 0.2);
}
.DataTable--active-row .Table--cell {
  background-color: #f0f3f4;
}
