.InputClearButton {
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  color: inherit;
  opacity: 0.5;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: 2;
}
.InputClearButton i {
  font-size: 22px;
  line-height: 1;
}
.InputClearButton:hover,
.InputClearButton:active {
  color: inherit !important;
  opacity: 1;
}
