/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.OutgoingAlignedGoals__outgoingAlignmentsContainer--lXHtF {
  margin-top: 3rem;
  margin-right: 0;
  margin-left: 0; }
  .OutgoingAlignedGoals__outgoingAlignmentsContainer--lXHtF[dir="rtl"],
  [dir="rtl"] .OutgoingAlignedGoals__outgoingAlignmentsContainer--lXHtF {
    margin-right: 0;
    margin-left: 0; }

.OutgoingAlignedGoals__outgoingAlignmentsLabel--1GaEL {
  margin-bottom: 1.875rem;
  margin-right: 0;
  margin-left: 0; }
  .OutgoingAlignedGoals__outgoingAlignmentsLabel--1GaEL[dir="rtl"],
  [dir="rtl"] .OutgoingAlignedGoals__outgoingAlignmentsLabel--1GaEL {
    margin-right: 0;
    margin-left: 0; }
