/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
.ButtonMenu {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.ButtonMenu--menu {
  position: absolute;
  left: 50%;
  top: 100%;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  visibility: hidden;
  opacity: 0;
  background: white;
  cursor: auto;
}
.ButtonMenu--open .ButtonMenu--menu {
  visibility: visible;
  opacity: 1;
  z-index: 8;
}
.ButtonMenu--tip-holder {
  position: absolute;
  left: 50%;
  top: -20px;
  width: 40px;
  height: 20px;
  overflow: hidden;
  margin: 0 0 0 -20px !important;
  padding: 0 !important;
  border: 0 none !important;
  list-style-type: none !important;
}
/**
 * The tip is a square rotated 45deg such that it rises @ButtonMenu--tip-height above
 * the menu.
 */
.ButtonMenu--tip {
  position: absolute;
  left: 20px;
  top: 10px;
  text-align: left;
  width: 14.14213562px;
  height: 14.14213562px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  background: white;
}
.ButtonMenu--menu,
.ButtonMenu--tip {
  -webkit-box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.09);
          box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.09);
  border: 1px solid #e4e4e4;
}
