/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.GoalOwnerSelect__goalOwnerSelect--1yJ-b {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.GoalOwnerSelect__labelContainer--2Cue9 {
  margin-right: 0;
  margin-left: 0.75rem; }
  .GoalOwnerSelect__labelContainer--2Cue9[dir="rtl"],
  [dir="rtl"] .GoalOwnerSelect__labelContainer--2Cue9 {
    margin-right: 0.75rem;
    margin-left: 0; }

.GoalOwnerSelect__titleContainer--2iAV9 {
  margin-bottom: 0.375rem;
  margin-right: 0;
  margin-left: 0; }
  .GoalOwnerSelect__titleContainer--2iAV9[dir="rtl"],
  [dir="rtl"] .GoalOwnerSelect__titleContainer--2iAV9 {
    margin-right: 0;
    margin-left: 0; }
