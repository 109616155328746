.GoalDateRangePicker {
  margin-right: 20px;
}
.GoalDateRangePicker--dropdown {
  font-weight: 400;
  min-width: 240px;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 500;
  letter-spacing: normal;
  font-size: 1.125rem;
  line-height: 2;
  position: relative;
  -webkit-font-smoothing: antialiased;
  margin-right: 5px;
}
.GoalDateRangePicker--dropdown-customDate {
  min-width: 240px;
  padding-right: 20px;
}
.GoalDateRangePicker--flatpickr {
  min-width: 240px;
  text-align: center;
}
.GoalDateRangePicker input {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
  border-radius: 3px;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.inRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #0168b3;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #fff;
  border-color: #0168b3;
}
.flatpickr-day.endRange {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.flatpickr-day:not(.inRange) + .flatpickr-day.endRange {
  border-radius: 25px !important;
}
.flatpickr-day {
  max-width: 45px;
  width: 45px;
  height: 45px;
}
.flatpickr-current-month {
  font-family: "Greycliff CF", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: -0.03em;
  font-size: 1.375rem;
  line-height: 1.09090909;
  position: relative;
  -webkit-font-smoothing: antialiased;
  position: absolute;
}
.flatpickr-weekday {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 500;
  letter-spacing: 0.04166667em;
  font-size: 0.75rem;
  line-height: 2;
  position: relative;
  -webkit-font-smoothing: antialiased;
  color: #35374a;
  opacity: 0.5 !important;
}
