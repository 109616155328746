/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
/**
 * Styles a container such that `@spacing` separates each item in the given `@direction`.
 *
 * Note that this mixin sets margin styles in the given direction on the container and on the items.
 * For example, with `item-spacing(horizontal, 10px)`, you'll have `margin-left` and `margin-right`
 * values on the container and on the items (rendered as `& > *`).
 *
 *
 * Parameters:
 *
 * - `@direction`: keyword. horizontal|vertical
 * - `@spacing`: length or percentage. Any numeric value that can be used as a `margin` should work.
 *
 * Usage:
 *
 *    .item-spacing(vertical; 5px);
 *    .item-spacing(horizontal; 10px);
 */
.ScrollableSideDrawer {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 90;
  border-left: 1px solid #e1e2ea;
  color: #35374a;
  background: white;
  width: 250px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin-right: -175px;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  overflow-y: auto;
}
.ScrollableSideDrawer.ScrollableSideDrawer--opened {
  margin-right: 0px;
}
.ScrollableSideDrawer.ScrollableSideDrawer--opened .ScrollableSideDrawer--items {
  overflow-y: auto;
}
.ScrollableSideDrawer.ScrollableSideDrawer--opened .ScrollableSideDrawer--header {
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  opacity: 1;
}
.ScrollableSideDrawer--close {
  color: rgba(53, 55, 74, 0.45);
  border-bottom: 1px solid #e1e2ea;
  cursor: pointer;
  padding: 6px 0px;
  min-height: 56px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}
.ScrollableSideDrawer--close:hover {
  color: #35374a;
}
.ScrollableSideDrawer--close .ScrollableSideDrawer--header {
  -webkit-transition: opacity 0.1s;
  transition: opacity 0.1s;
  opacity: 0;
}
.ScrollableSideDrawer hr {
  margin: 0px;
}
@media (max-width: 500px) {
  .ScrollableSideDrawer {
    display: none !important;
  }
}
.ScrollableSideDrawer--header {
  text-align: center;
  height: 100%;
  max-width: 135px;
  overflow: hidden;
  background-color: white;
  color: #35374a;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
  line-height: 15px;
}
.ScrollableSideDrawer--typeahead .Select-placeholder {
  padding-left: 75px;
}
.ScrollableSideDrawer--typeahead .Select-input {
  padding-left: 70px;
}
.ScrollableSideDrawer--typeahead .TypeaheadRowWithAvatar .Avatar {
  margin-right: 15px;
}
.ScrollableSideDrawer--footer {
  width: 100%;
  height: 50px;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
}
.ScrollableSideDrawer--avatar {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.ScrollableSideDrawer--avatar .Avatar {
  width: 40px !important;
  height: 40px !important;
}
.ScrollableSideDrawer--close-transition {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.ScrollableSideDrawer--item {
  color: #35374a;
  padding: 10px 10px 10px 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  height: 70px;
  cursor: pointer;
}
.ScrollableSideDrawer--item-not-selectable {
  opacity: 0.25;
  cursor: auto;
}
.ScrollableSideDrawer--item-selected {
  background-color: #f6f6f6;
  color: #0168b3;
}
.ScrollableSideDrawer--item-selected {
  background-color: #f6f6f6;
}
.ScrollableSideDrawer--peek-section {
  width: 75px;
}
.ScrollableSideDrawer--retracted-section {
  min-width: 0;
  text-align: left;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.ScrollableSideDrawerSection--tooltip {
  padding: 8px 16px;
  color: #35374a;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.ScrollableSideDrawer--relative-mode {
  position: relative;
  margin-right: 0;
}
.ScrollableSideDrawer--relative-mode.ScrollableSideDrawer--closed {
  width: 75px;
}
