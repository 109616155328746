/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
.TeamSummariesList__visibilityFilter--9ZTht {
  margin-bottom: 0.75rem;
  margin-right: 0;
  margin-left: 0;
  width: 13.5rem; }
  .TeamSummariesList__visibilityFilter--9ZTht[dir="rtl"],
  [dir="rtl"] .TeamSummariesList__visibilityFilter--9ZTht {
    margin-right: 0;
    margin-left: 0; }

.TeamSummariesList__search--3lAUD {
  background: white;
  margin-bottom: 1.5rem;
  margin-right: 0;
  margin-left: 0; }
  .TeamSummariesList__search--3lAUD[dir="rtl"],
  [dir="rtl"] .TeamSummariesList__search--3lAUD {
    margin-right: 0;
    margin-left: 0; }

.TeamSummariesList__team--37z3i {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

.TeamSummariesList__header--3aXA8 {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 0.75rem;
  margin-right: 25px;
  margin-left: 25px; }
  .TeamSummariesList__header--3aXA8[dir="rtl"],
  [dir="rtl"] .TeamSummariesList__header--3aXA8 {
    margin-right: 25px;
    margin-left: 25px; }
  @media (max-width: 767px) {
    .TeamSummariesList__header--3aXA8 {
      margin-bottom: 0.75rem;
      margin-right: 0;
      margin-left: 0.75rem; }
      .TeamSummariesList__header--3aXA8[dir="rtl"],
      [dir="rtl"] .TeamSummariesList__header--3aXA8 {
        margin-right: 0.75rem;
        margin-left: 0; } }

@media (max-width: 767px) {
  .TeamSummariesList__hideMobile--2Acof {
    display: none; } }

.TeamSummariesList__createButton--27Y0j {
  margin-bottom: 0.375rem;
  margin-right: 0;
  margin-left: 0;
  padding: 1.5rem;
  padding-top: 0; }
  .TeamSummariesList__createButton--27Y0j[dir="rtl"],
  [dir="rtl"] .TeamSummariesList__createButton--27Y0j {
    margin-right: 0;
    margin-left: 0; }

.TeamSummariesList__topSpace--2Xt6V {
  margin-top: 0.75rem;
  margin-right: 0;
  margin-left: 0; }
  .TeamSummariesList__topSpace--2Xt6V[dir="rtl"],
  [dir="rtl"] .TeamSummariesList__topSpace--2Xt6V {
    margin-right: 0;
    margin-left: 0; }

.TeamSummariesList__filter--2wqv5 {
  -ms-flex-preferred-size: 250px;
      flex-basis: 250px; }
  .TeamSummariesList__filter--2wqv5:not(:last-child) {
    margin-right: 1.5rem;
    margin-left: 0; }
    .TeamSummariesList__filter--2wqv5:not(:last-child)[dir="rtl"],
    [dir="rtl"] .TeamSummariesList__filter--2wqv5:not(:last-child) {
      margin-right: 0;
      margin-left: 1.5rem; }
  .TeamSummariesList__filter--2wqv5:nth-child(2) {
    z-index: 2; }
  @media (max-width: 767px) {
    .TeamSummariesList__filter--2wqv5 {
      -ms-flex-preferred-size: 4.5rem;
          flex-basis: 4.5rem; }
      .TeamSummariesList__filter--2wqv5:not(:last-child) {
        margin-right: 0;
        margin-left: 0; }
        .TeamSummariesList__filter--2wqv5:not(:last-child)[dir="rtl"],
        [dir="rtl"] .TeamSummariesList__filter--2wqv5:not(:last-child) {
          margin-right: 0;
          margin-left: 0; } }

.TeamSummariesList__filterRight--1tt3F {
  margin-left: auto;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }
  @media (max-width: 767px) {
    .TeamSummariesList__filterRight--1tt3F {
      width: 100%; } }
