.ConfirmationModal {
  margin-top: -10px;
}
.ConfirmationModal h2 {
  margin-top: 0;
}
.ConfirmationModal button {
  margin: 10px 5px;
  white-space: nowrap;
}
.ConfirmationModal--img {
  display: block;
  margin: 40px auto 0;
  width: 30%;
}
