/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
.TeamGoalProgressCell__container--3Rz8Y {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px; }
  .TeamGoalProgressCell__container--3Rz8Y[dir="rtl"],
  [dir="rtl"] .TeamGoalProgressCell__container--3Rz8Y {
    padding-right: 20px;
    padding-left: 20px; }
  @media (max-width: 767px) {
    .TeamGoalProgressCell__container--3Rz8Y {
      padding-right: 0;
      padding-left: 0; }
      .TeamGoalProgressCell__container--3Rz8Y[dir="rtl"],
      [dir="rtl"] .TeamGoalProgressCell__container--3Rz8Y {
        padding-right: 0;
        padding-left: 0; } }

.TeamGoalProgressCell__progressBar--1pgm7 {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
  height: 10px;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.TeamGoalProgressCell__progress--3Ib-J {
  background: #21a38b;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  overflow: hidden;
  -webkit-transition: width 300ms;
  transition: width 300ms; }
