.ExpandIndicator {
  -webkit-transition: 0.3s -webkit-transform;
  transition: 0.3s -webkit-transform;
  transition: 0.3s transform;
  transition: 0.3s transform, 0.3s -webkit-transform;
  margin-left: auto !important;
}
.ExpandIndicator--expanded {
  -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
}
