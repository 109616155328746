/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
.AvatarDetails__container--2VnUI {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.AvatarDetails__details--2o0ZG {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-right: 0;
  margin-left: 10px;
  color: #35374a; }
  .AvatarDetails__details--2o0ZG[dir="rtl"],
  [dir="rtl"] .AvatarDetails__details--2o0ZG {
    margin-right: 10px;
    margin-left: 0; }
  .AvatarDetails__details--2o0ZG *:last-child {
    opacity: 0.7; }
