/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.IncompleteTBFPage__page--3UhRw {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-right: 0;
  margin-left: 1.5rem; }
  .IncompleteTBFPage__page--3UhRw[dir="rtl"],
  [dir="rtl"] .IncompleteTBFPage__page--3UhRw {
    margin-right: 1.5rem;
    margin-left: 0; }

.IncompleteTBFPage__surveyContainer--13QeQ {
  margin-top: 1.5rem;
  margin-right: 1.5rem;
  margin-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .IncompleteTBFPage__surveyContainer--13QeQ[dir="rtl"],
  [dir="rtl"] .IncompleteTBFPage__surveyContainer--13QeQ {
    margin-right: 0;
    margin-left: 1.5rem; }
  @media (max-width: 767px) {
    .IncompleteTBFPage__surveyContainer--13QeQ {
      padding-top: 4.5rem;
      padding-right: 0;
      padding-left: 0; }
      .IncompleteTBFPage__surveyContainer--13QeQ[dir="rtl"],
      [dir="rtl"] .IncompleteTBFPage__surveyContainer--13QeQ {
        padding-right: 0;
        padding-left: 0; } }

.IncompleteTBFPage__form--f2Qe7 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 300px; }

.IncompleteTBFPage__userSelector--10saE {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex; }

.IncompleteTBFPage__userInfo--CtdCm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.IncompleteTBFPage__dismissButton--2Apkf {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem; }

.IncompleteTBFPage__visibilityFooter--1aulc {
  margin-bottom: 1.5rem;
  margin-right: 0;
  margin-left: 0;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71429;
  position: relative;
  top: 0.49714em; }
  .IncompleteTBFPage__visibilityFooter--1aulc[dir="rtl"],
  [dir="rtl"] .IncompleteTBFPage__visibilityFooter--1aulc {
    margin-right: 0;
    margin-left: 0; }
  html:lang(he) .IncompleteTBFPage__visibilityFooter--1aulc {
    font-family: "Open Sans", Tahoma, sans-serif; }
  html:lang(ar) .IncompleteTBFPage__visibilityFooter--1aulc {
    font-family: "Open Sans", Tahoma, sans-serif; }
  html:lang(he) .IncompleteTBFPage__visibilityFooter--1aulc {
    font-family: "Ideal Sans A", "Ideal Sans B", Tahoma, sans-serif; }
  html:lang(ar) .IncompleteTBFPage__visibilityFooter--1aulc {
    font-family: "Ideal Sans A", "Ideal Sans B", Tahoma, sans-serif; }

.IncompleteTBFPage__visibilityText--chgvu {
  top: 0 !important;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71429;
  position: relative;
  top: 0.49714em; }
  html:lang(he) .IncompleteTBFPage__visibilityText--chgvu {
    font-family: "Open Sans", Tahoma, sans-serif; }
  html:lang(ar) .IncompleteTBFPage__visibilityText--chgvu {
    font-family: "Open Sans", Tahoma, sans-serif; }
  html:lang(he) .IncompleteTBFPage__visibilityText--chgvu {
    font-family: "Ideal Sans A", "Ideal Sans B", Tahoma, sans-serif; }
  html:lang(ar) .IncompleteTBFPage__visibilityText--chgvu {
    font-family: "Ideal Sans A", "Ideal Sans B", Tahoma, sans-serif; }
