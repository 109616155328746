/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
/**
 * Styles a container such that `@spacing` separates each item in the given `@direction`.
 *
 * Note that this mixin sets margin styles in the given direction on the container and on the items.
 * For example, with `item-spacing(horizontal, 10px)`, you'll have `margin-left` and `margin-right`
 * values on the container and on the items (rendered as `& > *`).
 *
 *
 * Parameters:
 *
 * - `@direction`: keyword. horizontal|vertical
 * - `@spacing`: length or percentage. Any numeric value that can be used as a `margin` should work.
 *
 * Usage:
 *
 *    .item-spacing(vertical; 5px);
 *    .item-spacing(horizontal; 10px);
 */
.ProfileContent {
  color: #35374a;
  padding-top: 1.5rem;
}
@media (max-width: 500px) {
  .ProfileContent {
    padding-top: 4.5rem;
  }
}
.ProfileContent .Tabs--bar {
  margin-bottom: 25px;
}
.ProfileContent .GoalsList {
  margin-top: -20px;
}
.ProfileContent .GoalsList--empty-content {
  margin-top: 20px;
}
.ProfileContent .GoalsTable .NewGoalLabel {
  margin-left: 0;
}
.ProfileContent .GoalsTable--owner-avatar,
.ProfileContent .GoalsTable--owner-name {
  display: none;
}
.ProfileContent .GoalsTable--owner-name-cell.Table--header-cell > * {
  display: none;
}
.ProfileContent .GoalDateRangePicker {
  width: 260px;
}
.ProfileContent .Goals--options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ProfileContent .Table--header-cell {
  background-color: transparent !important;
  position: relative !important;
}
.ProfileContent--empty-state {
  margin: 25px auto;
  width: 100%;
}
.ProfileContent--empty-state-dossier {
  margin: 25px auto;
  max-width: 350px;
}
.ProfileContent--empty-state-dossier p,
.ProfileContent--empty-state-dossier h2 {
  text-align: center !important;
}
