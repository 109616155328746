/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.ProgressSlider__container--3TRrx {
  border-radius: 9px;
  border: 1px solid #d8dad9;
  height: 10px; }

.ProgressSlider__progress--1hlC6 {
  border-radius: 9px;
  height: 100%;
  background: #6b6e94;
  position: relative; }

.ProgressSlider__progressHandler--2J9Vj {
  padding-top: 1px;
  padding-bottom: 1px;
  padding-right: 10px;
  padding-left: 10px;
  background: #35374a;
  width: 50px;
  text-align: center;
  border-radius: 9px;
  height: 21px;
  position: absolute;
  top: -6px;
  cursor: pointer;
  right: -25px; }
  .ProgressSlider__progressHandler--2J9Vj[dir="rtl"],
  [dir="rtl"] .ProgressSlider__progressHandler--2J9Vj {
    padding-right: 10px;
    padding-left: 10px; }
  .ProgressSlider__progressHandler--2J9Vj span {
    font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: 300;
    letter-spacing: normal;
    font-size: 0.875rem;
    line-height: 1.71429;
    position: relative;
    top: 0.49714em;
    color: #fff;
    line-height: 20px;
    top: 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    html:lang(he) .ProgressSlider__progressHandler--2J9Vj span {
      font-family: "Open Sans", Tahoma, sans-serif; }
    html:lang(ar) .ProgressSlider__progressHandler--2J9Vj span {
      font-family: "Open Sans", Tahoma, sans-serif; }
    html:lang(he) .ProgressSlider__progressHandler--2J9Vj span {
      font-family: "Ideal Sans A", "Ideal Sans B", Tahoma, sans-serif; }
    html:lang(ar) .ProgressSlider__progressHandler--2J9Vj span {
      font-family: "Ideal Sans A", "Ideal Sans B", Tahoma, sans-serif; }
