.TabContent--content {
  display: none;
}
.TabContent--content-header {
  margin-top: 0;
  text-align: left;
}
.TabContent--content-active {
  display: block;
}
