/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
body {
  background: #f9f9f9;
  margin: 0; }

.ErrorPage__card--39zrW {
  max-width: 800px;
  margin: 0 auto;
  background: white;
  padding: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.ErrorPage__textContent--10ZD1 {
  max-width: 450px; }

.ErrorPage__imageContent--3jE4E {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end; }

.ErrorPage__errorCode--2mYv_ {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 15rem;
  line-height: 1;
  position: relative;
  top: 0.14em;
  margin: 0; }
  html:lang(he) .ErrorPage__errorCode--2mYv_ {
    font-family: "Open Sans", Tahoma, sans-serif; }
  html:lang(ar) .ErrorPage__errorCode--2mYv_ {
    font-family: "Open Sans", Tahoma, sans-serif; }
  html:lang(he) .ErrorPage__errorCode--2mYv_ {
    font-family: "Ideal Sans A", "Ideal Sans B", Tahoma, sans-serif; }
  html:lang(ar) .ErrorPage__errorCode--2mYv_ {
    font-family: "Ideal Sans A", "Ideal Sans B", Tahoma, sans-serif; }

.ErrorPage__paragraph--3YxjN {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  top: 0.39em; }
  html:lang(he) .ErrorPage__paragraph--3YxjN {
    font-family: "Open Sans", Tahoma, sans-serif; }
  html:lang(ar) .ErrorPage__paragraph--3YxjN {
    font-family: "Open Sans", Tahoma, sans-serif; }
  html:lang(he) .ErrorPage__paragraph--3YxjN {
    font-family: "Ideal Sans A", "Ideal Sans B", Tahoma, sans-serif; }
  html:lang(ar) .ErrorPage__paragraph--3YxjN {
    font-family: "Ideal Sans A", "Ideal Sans B", Tahoma, sans-serif; }

.ErrorPage__image--1XAr- {
  width: 300px;
  max-width: 100%; }

.ErrorPage__logo--hZEok {
  max-width: 100%; }

.ErrorPage__errorCode400--1RaJd {
  color: #f3786d; }

.ErrorPage__errorCode401--1c2Ti {
  color: #f9f9f9; }

.ErrorPage__errorCode403--2w55T,
.ErrorPage__errorCode422--1q6IJ {
  color: #253c64; }

.ErrorPage__errorCode404--1GHDs {
  color: #ffce1e; }

.ErrorPage__errorCode500--IA2Cz,
.ErrorPage__errorCode502--2DjGK {
  color: #f04c5d; }
