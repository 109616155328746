/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.GoalKeyResults__container--1Bjm6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }

.GoalKeyResults__keyResultsLabel--2U2WU {
  margin-bottom: 1.5rem;
  margin-right: 0;
  margin-left: 0; }
  .GoalKeyResults__keyResultsLabel--2U2WU[dir="rtl"],
  [dir="rtl"] .GoalKeyResults__keyResultsLabel--2U2WU {
    margin-right: 0;
    margin-left: 0; }

.GoalKeyResults__keyResult--3ClOl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 20px;
  margin-right: 0;
  margin-left: 0; }
  .GoalKeyResults__keyResult--3ClOl[dir="rtl"],
  [dir="rtl"] .GoalKeyResults__keyResult--3ClOl {
    margin-right: 0;
    margin-left: 0; }

.GoalKeyResults__keyResultTitle--2AaX7 {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  width: 50%; }

.GoalKeyResults__progress--obOKH {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-right: 0;
  margin-left: 3rem; }
  .GoalKeyResults__progress--obOKH[dir="rtl"],
  [dir="rtl"] .GoalKeyResults__progress--obOKH {
    margin-right: 3rem;
    margin-left: 0; }
