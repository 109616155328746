.AnimatedEllipsis,
.AnimatedEllipsis > * {
  display: inline-block;
}
@-webkit-keyframes AnimatedEllipsis--animation {
  0 {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0px, -2px);
            transform: translate(0px, -2px);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
@keyframes AnimatedEllipsis--animation {
  0 {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0px, -2px);
            transform: translate(0px, -2px);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
