/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.TeamsList__search--39aFq {
  background: white;
  margin-bottom: 1.5rem;
  margin-right: 0;
  margin-left: 0; }
  .TeamsList__search--39aFq[dir="rtl"],
  [dir="rtl"] .TeamsList__search--39aFq {
    margin-right: 0;
    margin-left: 0; }

.TeamsList__heading--2cJ9Q {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 1.5rem;
  margin-right: 0;
  margin-left: 0; }
  .TeamsList__heading--2cJ9Q[dir="rtl"],
  [dir="rtl"] .TeamsList__heading--2cJ9Q {
    margin-right: 0;
    margin-left: 0; }
