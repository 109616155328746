/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.Team__cards--3-bU1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.Team__header--10gfR,
.Team__navigation--3zbQR {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.Team__tabContainer--1VxGL {
  margin-bottom: 1.5rem;
  margin-right: 0;
  margin-left: 0; }
  .Team__tabContainer--1VxGL[dir="rtl"],
  [dir="rtl"] .Team__tabContainer--1VxGL {
    margin-right: 0;
    margin-left: 0; }

.Team__options--3DZNv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media (max-width: 767px) {
    .Team__options--3DZNv {
      padding: 0.75rem; } }
  .Team__options--3DZNv > * {
    margin-right: 0;
    margin-left: 0.75rem; }
    .Team__options--3DZNv > *[dir="rtl"],
    [dir="rtl"] .Team__options--3DZNv > * {
      margin-right: 0.75rem;
      margin-left: 0; }

.Team__description--1OzHh {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-right: 0;
  margin-left: 0; }
  .Team__description--1OzHh[dir="rtl"],
  [dir="rtl"] .Team__description--1OzHh {
    margin-right: 0;
    margin-left: 0; }
