/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
.SearchField__inputAndResults--G57XR {
  position: relative; }

.SearchField__results--2L56w {
  -webkit-box-shadow: 0 1px 7px rgba(0, 0, 0, 0.1);
          box-shadow: 0 1px 7px rgba(0, 0, 0, 0.1);
  border: 1px solid #e4e4e4;
  position: absolute;
  background: #fff;
  top: calc(100% - 3px);
  left: 0;
  right: 0;
  z-index: 1; }

.SearchField__result--2CH8F {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: transparent;
  border: none;
  font: inherit;
  margin: 0;
  padding: 0;
  -webkit-transition: none;
  transition: none;
  text-align: initial;
  display: block;
  width: 100%; }
  .SearchField__result--2CH8F:nth-of-type(odd) {
    background: #f6f6f6; }
  .SearchField__result--2CH8F:nth-of-type(even) {
    background: #fff; }
  .SearchField__result--2CH8F:focus, .SearchField__result--2CH8F.SearchField__focussed--1c2l3 {
    color: #0168b3; }

.SearchField__empty--2nffW {
  padding: 0.75rem;
  display: block;
  width: 100%; }
