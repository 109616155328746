.MessageGroup {
  margin-bottom: 12px;
}
.MessageGroup:last-of-type {
  margin-bottom: 0;
}
.MessageGroup--profile {
  color: rgba(53, 55, 74, 0.8);
  font-size: 60px;
}
.MessageGroup--author {
  color: #35374a;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 4px;
  display: inline;
}
.MessageGroup--author-deactivated {
  display: inline;
  color: rgba(53, 55, 74, 0.8);
  font-weight: 400;
  margin-left: 5px;
}
.MessageGroup--profile-pic {
  margin: 6px 0 0 6px;
}
.MessageGroup--content {
  text-align: left;
  margin: 6px 0 0 12px;
}
.MessageGroup--messages {
  width: 100%;
}
