/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.ArchivedTeam__cards--2x0dz {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.ArchivedTeam__header--3KxSG,
.ArchivedTeam__navigation--3A57s {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.ArchivedTeam__options--Mrg9F {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media (max-width: 767px) {
    .ArchivedTeam__options--Mrg9F {
      padding: 0.75rem; } }
  .ArchivedTeam__options--Mrg9F > * {
    margin-right: 0;
    margin-left: 0.75rem; }
    .ArchivedTeam__options--Mrg9F > *[dir="rtl"],
    [dir="rtl"] .ArchivedTeam__options--Mrg9F > * {
      margin-right: 0.75rem;
      margin-left: 0; }

.ArchivedTeam__description--2-IXG {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-right: 0;
  margin-left: 0; }
  .ArchivedTeam__description--2-IXG[dir="rtl"],
  [dir="rtl"] .ArchivedTeam__description--2-IXG {
    margin-right: 0;
    margin-left: 0; }
