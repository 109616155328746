.GoalKeyResultList--header {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1.125rem;
  line-height: 1.33333333;
  position: relative;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 5px;
}
.GoalKeyResultList ol {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.GoalKeyResultList--key-result-title {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
  min-width: 250px;
}
.GoalKeyResultList--key-result-completion {
  min-width: 100px;
}
.GoalKeyResultList--key-result-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 24px;
}
.GoalKeyResultList--key-result-completion .Slider {
  height: 18px;
}
