/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
.GoalOwnersCell__container--hS_7n {
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }

.GoalOwnersCell__avatar--2Bdgu {
  margin-right: -10px;
  margin-left: 0; }
  .GoalOwnersCell__avatar--2Bdgu[dir="rtl"],
  [dir="rtl"] .GoalOwnersCell__avatar--2Bdgu {
    margin-right: 0;
    margin-left: -10px; }
