/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
.TextEditor--editor {
  min-height: 6rem; }

.GoalDetails__container--1Rv0X {
  margin-top: 1.5rem;
  margin-right: 0;
  margin-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .GoalDetails__container--1Rv0X[dir="rtl"],
  [dir="rtl"] .GoalDetails__container--1Rv0X {
    margin-right: 0;
    margin-left: 0; }
  .GoalDetails__container--1Rv0X h3 {
    text-align: left; }

.GoalDetails__progress--2UMnH {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 0.75rem;
  margin-right: 0;
  margin-left: 0; }
  .GoalDetails__progress--2UMnH[dir="rtl"],
  [dir="rtl"] .GoalDetails__progress--2UMnH {
    margin-right: 0;
    margin-left: 0; }

.GoalDetails__progressSlider--3aT2U {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 0.75rem;
  margin-right: 0;
  margin-left: 0; }
  .GoalDetails__progressSlider--3aT2U[dir="rtl"],
  [dir="rtl"] .GoalDetails__progressSlider--3aT2U {
    margin-right: 0;
    margin-left: 0; }
  .GoalDetails__progressSlider--3aT2U :first-child {
    -webkit-box-flex: 0.5;
        -ms-flex-positive: 0.5;
            flex-grow: 0.5; }

.GoalDetails__slider--n6p54 {
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  margin-right: 0;
  margin-left: 0.75rem; }
  .GoalDetails__slider--n6p54[dir="rtl"],
  [dir="rtl"] .GoalDetails__slider--n6p54 {
    margin-right: 0.75rem;
    margin-left: 0; }

.GoalDetails__owners--48nKD {
  -ms-flex-item-align: stretch;
      align-self: stretch;
  margin-bottom: 0.75rem;
  margin-right: 0;
  margin-left: 0; }
  .GoalDetails__owners--48nKD[dir="rtl"],
  [dir="rtl"] .GoalDetails__owners--48nKD {
    margin-right: 0;
    margin-left: 0; }

.GoalDetails__comments--1O2i- {
  margin-top: 3rem;
  margin-right: 0;
  margin-left: 0; }
  .GoalDetails__comments--1O2i-[dir="rtl"],
  [dir="rtl"] .GoalDetails__comments--1O2i- {
    margin-right: 0;
    margin-left: 0; }

.GoalDetails__statusInfoContainer--1BmCM {
  margin-bottom: 3rem;
  margin-right: 0;
  margin-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .GoalDetails__statusInfoContainer--1BmCM[dir="rtl"],
  [dir="rtl"] .GoalDetails__statusInfoContainer--1BmCM {
    margin-right: 0;
    margin-left: 0; }
  .GoalDetails__statusInfoContainer--1BmCM h5 {
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
    margin-right: 0;
    margin-left: 0; }
    .GoalDetails__statusInfoContainer--1BmCM h5[dir="rtl"],
    [dir="rtl"] .GoalDetails__statusInfoContainer--1BmCM h5 {
      margin-right: 0;
      margin-left: 0; }

.GoalDetails__status--LvKmT {
  -ms-flex-preferred-size: 170px;
      flex-basis: 170px; }

.GoalDetails__actionsContainer--t5RlS {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 1.5rem;
  margin-right: 0;
  margin-left: 0; }
  .GoalDetails__actionsContainer--t5RlS[dir="rtl"],
  [dir="rtl"] .GoalDetails__actionsContainer--t5RlS {
    margin-right: 0;
    margin-left: 0; }

.GoalDetails__actions--ttIaH {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 0;
  margin-left: 0;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }
  .GoalDetails__actions--ttIaH[dir="rtl"],
  [dir="rtl"] .GoalDetails__actions--ttIaH {
    margin-right: 0;
    margin-left: 0; }
