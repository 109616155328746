/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.KeyResultListItem__container--1ZPWs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%; }

.KeyResultListItem__textFieldContainer--X9bUm {
  width: 100%; }

.KeyResultListItem__keyResultTitle--cwx1M {
  font-size: 1rem; }

.KeyResultListItem__peripheralContainer--X-Xbg {
  -webkit-animation-name: KeyResultListItem__fade-0-to-1--2oL9j;
          animation-name: KeyResultListItem__fade-0-to-1--2oL9j;
  -webkit-animation-duration: 400ms;
          animation-duration: 400ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 3.75rem;
  height: 3rem;
  border: 1px solid #d8dad9;
  background-color: #f6f6f6; }

@-webkit-keyframes KeyResultListItem__fade-0-to-1--2oL9j {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes KeyResultListItem__fade-0-to-1--2oL9j {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
  .KeyResultListItem__peripheralContainer--X-Xbg:focus-within {
    border-color: #0168b3;
    -webkit-box-shadow: 0 0 0 1px #d8dad9;
            box-shadow: 0 0 0 1px #d8dad9; }
