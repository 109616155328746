.RepositionableItemNumber {
  width: 20px;
  margin: -6px 10px 0px -6px;
  font-weight: 600;
  height: 70px;
}
.RepositionableItemNumber--move {
  font-size: 28px;
  color: rgba(53, 55, 74, 0.45);
  cursor: pointer;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.RepositionableItemNumber--move:hover {
  color: #35374a;
}
.RepositionableItemNumber--move-hidden {
  visibility: hidden;
}
