/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.GoalsTableHeader__header--2B4lL {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  @media (max-width: 767px) {
    .GoalsTableHeader__header--2B4lL {
      margin-bottom: 0.75rem;
      margin-right: 0;
      margin-left: 0.75rem; }
      .GoalsTableHeader__header--2B4lL[dir="rtl"],
      [dir="rtl"] .GoalsTableHeader__header--2B4lL {
        margin-right: 0.75rem;
        margin-left: 0; } }

@media (max-width: 767px) {
  .GoalsTableHeader__hideMobile--179A- {
    display: none; } }
