/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.TeamsApp__teams--1LxRd {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  height: 100vh; }
  .TeamsApp__teams--1LxRd h1,
  .TeamsApp__teams--1LxRd h2,
  .TeamsApp__teams--1LxRd h3,
  .TeamsApp__teams--1LxRd h4,
  .TeamsApp__teams--1LxRd h5 {
    text-align: inherit; }
  .TeamsApp__teams--1LxRd select {
    display: initial; }

.TeamsApp__content--1oUH- {
  padding: 20px 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  height: 100%;
  overflow-y: scroll; }
  @media (max-width: 767px) {
    .TeamsApp__content--1oUH- {
      padding-top: 0.75rem;
      padding-bottom: 4.5rem;
      padding-right: 0.75rem;
      padding-left: 0.75rem; }
      .TeamsApp__content--1oUH-[dir="rtl"],
      [dir="rtl"] .TeamsApp__content--1oUH- {
        padding-right: 0.75rem;
        padding-left: 0.75rem; } }

.TeamsApp__dossier--Ep9Iy {
  width: 600px;
  height: 100%;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  background: #ffffff; }
  @media (max-width: 767px) {
    .TeamsApp__dossier--Ep9Iy {
      display: none; } }
