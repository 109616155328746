/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
.GoalsGrid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding-top: 40px;
}
@media (max-width: 900px) {
  .GoalsGrid {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.GoalsGrid .GoalPriorityIndicator {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 500;
  letter-spacing: 0.04166667em;
  font-size: 0.75rem;
  line-height: 2;
  position: relative;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
}
.GoalsGrid--order-dropdown {
  width: 160px;
  margin-bottom: 20px;
}
.GoalsGrid--column {
  padding: 0 20px 0 20px;
  width: 400px;
  border-right: 1px solid #e1e2ea;
}
.GoalsGrid--column:first-of-type {
  padding-left: 0px !important;
}
.GoalsGrid--column:last-of-type {
  border-right: none;
}
.GoalsGrid--column-draggingOver {
  -webkit-box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
}
.GoalsGrid--column-header {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1.125rem;
  line-height: 1.33333333;
  position: relative;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 20px;
  text-align: left;
}
.GoalsGrid--create-goal-card,
.GoalsGrid--card {
  margin: 0 0 20px 0;
  min-width: 0;
  width: 100%;
  max-height: 250px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: white;
  cursor: pointer;
  overflow: hidden;
  border-radius: 3px;
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}
.GoalsGrid--create-goal-card:hover,
.GoalsGrid--card:hover {
  -webkit-box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
}
.GoalsGrid--create-goal-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px dashed #e1e2ea;
  text-align: center;
  color: #0168b3;
  background-color: transparent;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.GoalsGrid--create-goal-card .GoalsGrid--create-goal-card-title {
  max-width: 100%;
}
.GoalsGrid--card {
  border: 1px solid #e1e2ea;
}
.GoalsGrid--card-interactive-indicator {
  position: absolute;
  height: 24px;
  margin: auto;
  top: 0;
  right: 10px;
  bottom: 0;
  color: #9898ad;
}
.GoalsGrid--create-goal-card {
  padding: 18px;
}
.GoalsGrid--card-name {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 10px;
  max-height: 130px;
}
.GoalsGrid--card-due-at {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
  opacity: 0.7;
  margin-bottom: -12px;
  margin-right: 4px;
  text-align: left;
}
.GoalsGrid--seeAll {
  width: 100%;
}
.GoalsGrid--single-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.GoalsGrid--single-column > .GoalsGrid--column {
  width: 100%;
  max-width: 100%;
  border-right: none;
  padding: 0;
}
.GoalsGrid--single-column .GoalsGrid--card {
  max-height: 215px;
}
.GoalsGrid--single-column .GoalsGrid--card-name {
  width: 90%;
}
.GoalsGrid--single-column .GoalProgressBar,
.GoalsGrid--single-column .GoalsGrid--card-due-row {
  max-width: 400px;
  border-radius: 0;
}
.GoalsGrid--blocked-icon {
  position: absolute;
  right: 10px;
  top: 15px;
  color: #ffce1e;
}
.GoalsGrid--card-content {
  padding: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.GoalGrid--card-status-container {
  background-color: aqua;
  border-radius: 15px 15px 15px 15px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  vertical-align: middle;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
  line-height: 0.9;
  max-width: 100px;
}
.GoalGrid--card-status-container span {
  padding: 0 10px;
  vertical-align: middle;
}
.GoalGrid--card-blocked {
  background: #fff1ee;
  color: #e1694f;
}
.GoalGrid--card-ongoing {
  background: #f3f3f6;
  color: #7b7d98;
}
.GoalGrid--card-created {
  background: #ebebed;
  color: #303243;
}
.GoalGrid--card-accomplished {
  background: #e9f6f3;
  color: #1e937d;
}
