.FeedbackReactionsManager {
  padding: 20px 0;
  text-align: center;
  min-width: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: white;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 3px;
}
.FeedbackReactionsManager h3 {
  margin: 0;
  padding-bottom: 15px;
}
.FeedbackReactionsManager--close-btn {
  color: #35374a;
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 25px;
  line-height: 25px;
  opacity: 0.5;
  z-index: 5;
}
.FeedbackReactionsManager--close-btn:hover {
  cursor: pointer;
  opacity: 1;
}
.FeedbackReactionsManager--question {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
  padding-bottom: 10px;
}
.FeedbackReactionsManager--footer-message {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 0.875rem;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.FeedbackReactionsManager--footer-message:last-child {
  margin-top: -5px;
}
.FeedbackReactionsManager--container {
  background-color: #f6f6f6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 15px 30px 5px;
  border-top: 1px solid #e1e2ea;
  border-bottom: 1px solid #e1e2ea;
}
.FeedbackReactionsManager--questions-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-bottom: 15px;
}
.FeedbackReactionsManager--buttons-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-top: 20px;
}
.FeedbackReactionsManager--buttons-container span:first-of-type {
  padding-right: 5px;
}
.FeedbackReactionsManager--buttons-container span:last-of-type {
  padding-left: 5px;
}
.FeedbackReactionsManager--options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.FeedbackReactionsManager--options .Checkbox {
  padding-right: 30px;
}
.FeedbackReactionsManager--options .Checkbox .Checkbox--label {
  padding-left: 25px;
}
