/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
.AlignedGoalsSection__outgoingGoalAlignments--1uV_E {
  margin-top: 0.75rem;
  margin-right: 0;
  margin-left: 0; }
  .AlignedGoalsSection__outgoingGoalAlignments--1uV_E[dir="rtl"],
  [dir="rtl"] .AlignedGoalsSection__outgoingGoalAlignments--1uV_E {
    margin-right: 0;
    margin-left: 0; }

.AlignedGoalsSection__alignedGoal--1XXfq {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: #ececef;
  cursor: pointer;
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
  margin-right: 0;
  margin-left: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 0.75rem;
  padding-left: 0.75rem; }
  .AlignedGoalsSection__alignedGoal--1XXfq[dir="rtl"],
  [dir="rtl"] .AlignedGoalsSection__alignedGoal--1XXfq {
    margin-right: 0;
    margin-left: 0; }
  .AlignedGoalsSection__alignedGoal--1XXfq[dir="rtl"],
  [dir="rtl"] .AlignedGoalsSection__alignedGoal--1XXfq {
    padding-right: 0.75rem;
    padding-left: 0.75rem; }
  .AlignedGoalsSection__alignedGoal--1XXfq:hover {
    background-color: #e0e0e3; }
