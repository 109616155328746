/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.DepartmentGoalForm__container--16ssl {
  max-width: 1080px;
  margin: auto; }
  @media (max-width: 767px) {
    .DepartmentGoalForm__container--16ssl {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-right: 0.75rem;
      padding-left: 0.75rem; }
      .DepartmentGoalForm__container--16ssl[dir="rtl"],
      [dir="rtl"] .DepartmentGoalForm__container--16ssl {
        padding-right: 0.75rem;
        padding-left: 0.75rem; } }

.DepartmentGoalForm__section--1FG6S {
  margin-bottom: 1.5rem;
  margin-right: 0;
  margin-left: 0; }
  .DepartmentGoalForm__section--1FG6S[dir="rtl"],
  [dir="rtl"] .DepartmentGoalForm__section--1FG6S {
    margin-right: 0;
    margin-left: 0; }

.DepartmentGoalForm__row--3pi0Q {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }
  @media (max-width: 1023px) {
    .DepartmentGoalForm__row--3pi0Q {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      margin-bottom: 1.5rem;
      margin-right: 0;
      margin-left: 0; }
      .DepartmentGoalForm__row--3pi0Q[dir="rtl"],
      [dir="rtl"] .DepartmentGoalForm__row--3pi0Q {
        margin-right: 0;
        margin-left: 0; } }

.DepartmentGoalForm__field--37bNH {
  margin-right: 0.75rem;
  margin-left: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0; }
  .DepartmentGoalForm__field--37bNH[dir="rtl"],
  [dir="rtl"] .DepartmentGoalForm__field--37bNH {
    margin-right: 0;
    margin-left: 0.75rem; }
  .DepartmentGoalForm__field--37bNH:last-child {
    margin-right: 0;
    margin-left: 0; }
    .DepartmentGoalForm__field--37bNH:last-child[dir="rtl"],
    [dir="rtl"] .DepartmentGoalForm__field--37bNH:last-child {
      margin-right: 0;
      margin-left: 0; }
  @media (max-width: 1023px) {
    .DepartmentGoalForm__field--37bNH {
      width: 100%; } }

.DepartmentGoalForm__large--3l2BR {
  -webkit-box-flex: 5;
      -ms-flex-positive: 5;
          flex-grow: 5; }

.DepartmentGoalForm__small--31yod {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

.DepartmentGoalForm__actions--76grv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

.DepartmentGoalForm__action--3UDbb {
  margin-right: 0.75rem;
  margin-left: 0; }
  .DepartmentGoalForm__action--3UDbb[dir="rtl"],
  [dir="rtl"] .DepartmentGoalForm__action--3UDbb {
    margin-right: 0;
    margin-left: 0.75rem; }
  .DepartmentGoalForm__action--3UDbb:last-child {
    margin-right: 0;
    margin-left: 0; }
    .DepartmentGoalForm__action--3UDbb:last-child[dir="rtl"],
    [dir="rtl"] .DepartmentGoalForm__action--3UDbb:last-child {
      margin-right: 0;
      margin-left: 0; }

.DepartmentGoalForm__selectField--39_7A {
  display: block; }

.DepartmentGoalForm__goalOwnerSelect--3vr5d {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.DepartmentGoalForm__labelContainer--2Gwuf {
  margin-right: 0;
  margin-left: 0.75rem; }
  .DepartmentGoalForm__labelContainer--2Gwuf[dir="rtl"],
  [dir="rtl"] .DepartmentGoalForm__labelContainer--2Gwuf {
    margin-right: 0.75rem;
    margin-left: 0; }

.DepartmentGoalForm__detailedDescriptionContainer--19Mc4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%; }

.DepartmentGoalForm__detailedDescriptionButton--2K343 {
  margin-left: auto; }

.DepartmentGoalForm__labelText--3-XLw {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1.125rem;
  line-height: 1.33333;
  position: relative;
  top: 0.30667em;
  margin-bottom: 0.75rem;
  margin-right: 0;
  margin-left: 0; }
  html:lang(he) .DepartmentGoalForm__labelText--3-XLw {
    font-family: "Open Sans", Tahoma, sans-serif; }
  html:lang(ar) .DepartmentGoalForm__labelText--3-XLw {
    font-family: "Open Sans", Tahoma, sans-serif; }
  html:lang(he) .DepartmentGoalForm__labelText--3-XLw {
    font-family: "Ideal Sans A", "Ideal Sans B", Tahoma, sans-serif; }
  html:lang(ar) .DepartmentGoalForm__labelText--3-XLw {
    font-family: "Ideal Sans A", "Ideal Sans B", Tahoma, sans-serif; }
  .DepartmentGoalForm__labelText--3-XLw[dir="rtl"],
  [dir="rtl"] .DepartmentGoalForm__labelText--3-XLw {
    margin-right: 0;
    margin-left: 0; }

.DepartmentGoalForm__titleContainer--3jXhF {
  margin-bottom: 0.375rem;
  margin-right: 0;
  margin-left: 0; }
  .DepartmentGoalForm__titleContainer--3jXhF[dir="rtl"],
  [dir="rtl"] .DepartmentGoalForm__titleContainer--3jXhF {
    margin-right: 0;
    margin-left: 0; }

.DepartmentGoalForm__priorityContainer--3xfDF {
  padding-top: 0.75rem;
  padding-right: 0;
  padding-left: 0; }
  .DepartmentGoalForm__priorityContainer--3xfDF[dir="rtl"],
  [dir="rtl"] .DepartmentGoalForm__priorityContainer--3xfDF {
    padding-right: 0;
    padding-left: 0; }
