/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
.TeamFilterBar .TeamFilterBar__group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 100%;
  margin-bottom: 15px;
}
.TeamFilterBar .TeamFilterBar__item {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-right: 12px;
}
.TeamFilterBar .TeamFilterBar__item:last-child {
  margin-right: 0;
}
.TeamFilterBar .SearchField {
  background-color: white;
  margin-bottom: 15px;
}
.TeamFilterBar .SearchField input::-webkit-input-placeholder {
  color: rgba(53, 55, 74, 0.8);
}
.TeamFilterBar .SearchField input::-moz-placeholder {
  color: rgba(53, 55, 74, 0.8);
}
.TeamFilterBar .SearchField input::-ms-input-placeholder {
  color: rgba(53, 55, 74, 0.8);
}
.TeamFilterBar .SearchField input::placeholder {
  color: rgba(53, 55, 74, 0.8);
}
