/** THIS IS AN AUTOGENERATED FILE **/
/* 16px */
/* 16px */
/* 14px */
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-demi-bold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Greycliff CF";
  src: url("https://d1e7r7b0lb8p4d.cloudfront.net/fonts/greycliff/greycliff-cf-extra-bold.woff") format("woff");
  font-weight: 800;
}
/** THIS IS AN AUTOGENERATED FILE **/
/**
 * CAPITALIZE our custom less variables here
 * to distinguish them from 3rd party ones
 */
/**
 * CAMPIFICATION BEGINS
 * All colours should eventually come only from the @kaizen/design-tokens package.
 */
.Typeahead--zugata-style,
.Typeahead--zugata-style.Select {
  border: 0;
}
.Typeahead--zugata-style .Select-menu,
.Typeahead--zugata-style .Select-menu-outer {
  max-height: 300px;
}
.Typeahead--zugata-style .Select-control {
  text-align: left;
  border-radius: 3px;
  border: 1px solid #e1e2ea;
  -webkit-box-shadow: none;
          box-shadow: none;
  height: 54px;
  padding: 0;
}
.Typeahead--zugata-style .Select-control .Select-multi-value-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.Typeahead--zugata-style .Select-control:hover {
  background-color: #f6f6f6;
}
@media (max-width: 500px) {
  .Typeahead--zugata-style .Select-control {
    display: block;
  }
}
.Typeahead--zugata-style .Select-aria-only {
  display: none;
}
.Typeahead--zugata-style .Select-menu-outer {
  border: 1px solid #e1e2ea;
  z-index: 5;
  overflow: hidden;
}
.Typeahead--zugata-style .Select-menu-outer .Select-option {
  text-align: left;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.Typeahead--zugata-style .Select-menu-outer .Select-option:hover,
.Typeahead--zugata-style .Select-menu-outer .Select-option:active,
.Typeahead--zugata-style .Select-menu-outer .Select-option:focus,
.Typeahead--zugata-style .Select-menu-outer .Select-option.is-focused {
  color: #0168b3;
  background-color: #f6f6f6;
}
.Typeahead--zugata-style .Select-menu-outer .Select-option.is-disabled:hover {
  background-color: white;
}
.Typeahead--zugata-style.is-open .Select-control {
  border-radius: 3px 3px 0 0;
}
.Typeahead--zugata-style.is-open .Select-menu-outer {
  border-radius: 0 0 3px 3px;
}
.Typeahead--zugata-style.is-focused .Select-control {
  border: 1px solid #e1e2ea;
}
.Typeahead--zugata-style.is-focused .Select-placeholder {
  display: none;
}
.Typeahead--zugata-style.is-disabled .Select-control {
  background: white;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.Typeahead--zugata-style.is-disabled .Select-input {
  display: none;
}
.Typeahead--zugata-style.is-disabled .Select-value {
  color: #35374a;
}
.Typeahead--zugata-style.Select--multi .Select-value {
  overflow: hidden;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #35374a;
  border-width: 0;
  background-color: rgba(53, 55, 74, 0.05);
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
  border-radius: 19.5px;
  margin-top: 0;
  margin-bottom: 0;
}
.Typeahead--zugata-style.Select--multi .Select-value .Select-value-icon {
  display: none !important;
  border-right: none;
  padding: 6px 6px 6px 8px;
  font-size: 18px;
  color: #9898ad;
}
.Typeahead--zugata-style.Select--multi .Select-value .Select-value-icon i {
  background-color: aqua;
}
.Typeahead--zugata-style.Select--multi .Select-value .Select-value-icon:hover {
  background-color: transparent;
  color: #21a38b;
}
.Typeahead--zugata-style.Select--multi .Select-value .Select-value-label {
  padding-right: 8px;
  padding-left: 0;
  font-size: 14px;
}
@media (max-width: 500px) {
  .Typeahead--zugata-style.Select--multi .Select-value {
    font-size: 12px;
  }
}
.Typeahead--zugata-style.Select--single .Select-value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.Typeahead--zugata-style .Select-placeholder {
  line-height: 52px;
  padding-right: 30px;
  padding-left: 15px;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
  color: #35374a;
}
@media (max-width: 500px) {
  .Typeahead--zugata-style .Select-placeholder {
    max-width: 210px;
  }
}
.Typeahead--zugata-style .Select-placeholder span {
  opacity: 0.7 !important;
}
.Typeahead--zugata-style .Select-placeholder i {
  margin-right: 8px;
}
.Typeahead--zugata-style .SelectArrow {
  position: static;
  padding: 0;
}
.Typeahead--zugata-style .Select-input {
  height: 52px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.Typeahead--zugata-style .arrow-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.Typeahead--zugata-style .Select-value-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1.125rem;
  line-height: 1.33333333;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.Typeahead--drawer-style {
  width: 100%;
  font-size: 16px;
}
.Typeahead--drawer-style .Select-control {
  border-top: 1px solid #e1e2ea;
  border-bottom: 1px solid #e1e2ea;
  border-right: none;
  border-left: none;
  border-radius: 0;
}
.Typeahead--drawer-style .Select-control:hover {
  background-color: #f6f6f6;
}
.Typeahead--drawer-style .Select-menu-outer {
  max-height: 355px;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.Typeahead--drawer-style .Select-menu {
  max-height: 355px;
}
.Typeahead--drawer-style .Select-value {
  background-color: #f6f6f6;
}
.Typeahead--drawer-style.is-open .Select-control {
  border-radius: 0;
  border-right: none;
  border-left: none;
}
.Typeahead--drawer-style.is-open .Select-menu-outer {
  border-radius: 0;
  border-right: none;
  border-left: none;
}
.Typeahead--drawer-style.is-focused .Select-control {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.Typeahead--drawer-style.Select--multi .Select-value {
  border-radius: 0;
}
.Typeahead--placeholder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
