/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
.PersonalGoalForm__container--1ovNU {
  max-width: 1080px;
  margin: auto; }
  @media (max-width: 767px) {
    .PersonalGoalForm__container--1ovNU {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-right: 0.75rem;
      padding-left: 0.75rem; }
      .PersonalGoalForm__container--1ovNU[dir="rtl"],
      [dir="rtl"] .PersonalGoalForm__container--1ovNU {
        padding-right: 0.75rem;
        padding-left: 0.75rem; } }

.PersonalGoalForm__row--2WPpX {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }
  @media (max-width: 1023px) {
    .PersonalGoalForm__row--2WPpX {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      margin-bottom: 1.5rem;
      margin-right: 0;
      margin-left: 0; }
      .PersonalGoalForm__row--2WPpX[dir="rtl"],
      [dir="rtl"] .PersonalGoalForm__row--2WPpX {
        margin-right: 0;
        margin-left: 0; } }

.PersonalGoalForm__section--2yC5p {
  margin-bottom: 1.5rem;
  margin-right: 0;
  margin-left: 0; }
  .PersonalGoalForm__section--2yC5p[dir="rtl"],
  [dir="rtl"] .PersonalGoalForm__section--2yC5p {
    margin-right: 0;
    margin-left: 0; }

.PersonalGoalForm__field--fTz3c {
  margin-right: 0.75rem;
  margin-left: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0; }
  .PersonalGoalForm__field--fTz3c[dir="rtl"],
  [dir="rtl"] .PersonalGoalForm__field--fTz3c {
    margin-right: 0;
    margin-left: 0.75rem; }
  .PersonalGoalForm__field--fTz3c:last-child {
    margin-right: 0;
    margin-left: 0; }
    .PersonalGoalForm__field--fTz3c:last-child[dir="rtl"],
    [dir="rtl"] .PersonalGoalForm__field--fTz3c:last-child {
      margin-right: 0;
      margin-left: 0; }
  @media (max-width: 1023px) {
    .PersonalGoalForm__field--fTz3c {
      width: 100%; } }

.PersonalGoalForm__large--2v3EF {
  -webkit-box-flex: 5;
      -ms-flex-positive: 5;
          flex-grow: 5; }

.PersonalGoalForm__small--gCydc {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

.PersonalGoalForm__actions--3ToRq {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

.PersonalGoalForm__action--20ZW3 {
  margin-right: 0.75rem;
  margin-left: 0; }
  .PersonalGoalForm__action--20ZW3[dir="rtl"],
  [dir="rtl"] .PersonalGoalForm__action--20ZW3 {
    margin-right: 0;
    margin-left: 0.75rem; }
  .PersonalGoalForm__action--20ZW3:last-child {
    margin-right: 0;
    margin-left: 0; }
    .PersonalGoalForm__action--20ZW3:last-child[dir="rtl"],
    [dir="rtl"] .PersonalGoalForm__action--20ZW3:last-child {
      margin-right: 0;
      margin-left: 0; }

.PersonalGoalForm__selectField--3FO0m {
  display: block; }

.PersonalGoalForm__labelText--20rqa {
  font-family: "Ideal Sans A", "Ideal Sans B", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 1.125rem;
  line-height: 1.33333;
  position: relative;
  top: 0.30667em;
  margin-bottom: 0.75rem;
  margin-right: 0;
  margin-left: 0; }
  html:lang(he) .PersonalGoalForm__labelText--20rqa {
    font-family: "Open Sans", Tahoma, sans-serif; }
  html:lang(ar) .PersonalGoalForm__labelText--20rqa {
    font-family: "Open Sans", Tahoma, sans-serif; }
  html:lang(he) .PersonalGoalForm__labelText--20rqa {
    font-family: "Ideal Sans A", "Ideal Sans B", Tahoma, sans-serif; }
  html:lang(ar) .PersonalGoalForm__labelText--20rqa {
    font-family: "Ideal Sans A", "Ideal Sans B", Tahoma, sans-serif; }
  .PersonalGoalForm__labelText--20rqa[dir="rtl"],
  [dir="rtl"] .PersonalGoalForm__labelText--20rqa {
    margin-right: 0;
    margin-left: 0; }

.PersonalGoalForm__priorityContainer--PwdaQ {
  padding-top: 0.75rem;
  padding-right: 0;
  padding-left: 0; }
  .PersonalGoalForm__priorityContainer--PwdaQ[dir="rtl"],
  [dir="rtl"] .PersonalGoalForm__priorityContainer--PwdaQ {
    padding-right: 0;
    padding-left: 0; }
