/** THIS IS AN AUTOGENERATED FILE **/
/** THIS IS AN AUTOGENERATED FILE **/
/* 14px */
/* 16px */
/** THIS IS AN AUTOGENERATED FILE **/
.DepartmentSummariesList__department--2JDRb {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

.DepartmentSummariesList__header--2ZLE- {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 0.75rem;
  margin-right: 25px;
  margin-left: 25px; }
  .DepartmentSummariesList__header--2ZLE-[dir="rtl"],
  [dir="rtl"] .DepartmentSummariesList__header--2ZLE- {
    margin-right: 25px;
    margin-left: 25px; }

.DepartmentSummariesList__topSpace--24v2u {
  margin-top: 0.75rem;
  margin-right: 0;
  margin-left: 0; }
  .DepartmentSummariesList__topSpace--24v2u[dir="rtl"],
  [dir="rtl"] .DepartmentSummariesList__topSpace--24v2u {
    margin-right: 0;
    margin-left: 0; }

.DepartmentSummariesList__filter--39tq- {
  -ms-flex-preferred-size: 250px;
      flex-basis: 250px; }
  .DepartmentSummariesList__filter--39tq-:not(:last-child) {
    margin-right: 1.5rem;
    margin-left: 0; }
    .DepartmentSummariesList__filter--39tq-:not(:last-child)[dir="rtl"],
    [dir="rtl"] .DepartmentSummariesList__filter--39tq-:not(:last-child) {
      margin-right: 0;
      margin-left: 1.5rem; }
  .DepartmentSummariesList__filter--39tq-:nth-child(2) {
    z-index: 2; }
  @media (max-width: 767px) {
    .DepartmentSummariesList__filter--39tq- {
      -ms-flex-preferred-size: 4.5rem;
          flex-basis: 4.5rem; }
      .DepartmentSummariesList__filter--39tq-:not(:last-child) {
        margin-right: 0;
        margin-left: 0; }
        .DepartmentSummariesList__filter--39tq-:not(:last-child)[dir="rtl"],
        [dir="rtl"] .DepartmentSummariesList__filter--39tq-:not(:last-child) {
          margin-right: 0;
          margin-left: 0; } }

.DepartmentSummariesList__filterRight--3V-ow {
  margin-left: auto;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-bottom: 0;
  margin-right: 0;
  margin-left: 0; }
  .DepartmentSummariesList__filterRight--3V-ow[dir="rtl"],
  [dir="rtl"] .DepartmentSummariesList__filterRight--3V-ow {
    margin-right: 0;
    margin-left: 0; }
  @media (max-width: 767px) {
    .DepartmentSummariesList__filterRight--3V-ow {
      width: 100%; } }
